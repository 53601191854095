import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Typography, Grid, Button, CircularProgress, InputAdornment, TablePagination } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import renderContentBlock from 'utils/contentRenderer';
import { appendSeoBrand } from 'utils/seo';
import { fetchCollection } from 'actions/CollectionActions';
import Formsy from "formsy-react";
import SearchIcon from "@material-ui/icons/Search";
import TextInput from 'components/inputs/TextInput';
import CardDesign from 'components/CardDesign';
import * as qs from 'query-string';
import _ from 'lodash';

export class CollectionPage extends React.Component {
  constructor(props) {
    super(props);

    const params = qs.parse(location.search);

    this.state = {
      page: parseInt(params['page']) || 0,
      search: params['search'] || ''
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    this.props.fetchCollection(window.SITE_ID, this.props.match.params.collectionSlug, this.props.locale);
  }

  // To handle case where navigating from one collection to another, which doesn't remount but changes the ID in the URL
  // Also handles when locale is changed and new translations need to be pulled
  componentDidUpdate(prevProps) {
    if (this.props.match.params.collectionSlug != prevProps.match.params.collectionSlug || this.props.locale !== prevProps.locale) {
      this.props.fetchCollection(window.SITE_ID, this.props.match.params.collectionSlug, this.props.locale);
    }
  }

  handleChangePage(event, newpage) {
    this.setState({page: newpage});
  }

  handleChangeSearch(data) {
    this.setState({search: data.product_search})
    this.setState({page: 0});
  }

  render() {
    const { collection, isFetching, intl, cardDesigns } = this.props;
    const rowsPerPage = 8;

    var params = {
      page: this.state.page
    }
    if (this.state.search) {
      params.search = this.state.search
    }
    window.history.replaceState(null, null, `${location.pathname}?${qs.stringify(params)}`);

    if (collection) {
      var products = _.compact(_.map(collection.card_designs, (c) => { return _.find(cardDesigns, {id: c.id}) }));
      if (this.state.search) {
        products = _.filter(products, (p) => { return p.name.toLowerCase().includes(this.state.search.toLowerCase()) });
      }
    }

    return (
      <React.Fragment>
        {isFetching &&
          <center><CircularProgress color="inherit" size={50} /></center>
        }

        {collection &&
          <React.Fragment>
            <Helmet>
              <title>{appendSeoBrand(collection.page_title)}</title>
              <meta name="description" content={collection.description} />
              <meta name="robots" content={window.APP_ENV === 'production' ? 'index' : 'noindex'} />
              <link rel="canonical" href={window.location.href} />
            </Helmet>

            <Typography variant='h4' component="h1" align='center' className="bottom-space">{collection.name}</Typography>
            <Grid container spacing={8} justify={collection.card_designs.length === 1 ? 'center' : 'flex-start'} align='center' className="content-item-container">
              {collection.card_designs.length > 8 &&
                <Grid item xs={12} align="left">
                  <Formsy onChange={this.handleChangeSearch}>
                    <TextInput type="search" name="product_search" value={this.state.search} label={intl.formatMessage({id: "order.search", defaultMessage: "Search"})} inlineLabel
                               inputProps={{
                                 endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>
                               }}
                    />
                  </Formsy>
                </Grid>
              }
              {products.slice(this.state.page * rowsPerPage, this.state.page * rowsPerPage + rowsPerPage).map((card_design, index) =>
                <Grid item key={index} xs={12} md={3}>
                  <CardDesign template='grid' cardDesign={card_design}/>
                </Grid>
              )}
              {products.length > rowsPerPage &&
                <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    component="div"
                    count={products.length}
                    rowsPerPage={rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                  />
                </Grid>
              }

              <Grid item xs={12} style={{marginTop: '3em'}} />
              {collection.content_blocks.map((block, index) =>
                <Grid item key={index} xs={12}>
                  {block.title && block.template != 'two_columns' && block.template != 'video' &&
                    <Typography variant="h5" align="left" className="bottom-space">{block.title}</Typography>
                  }
                  {renderContentBlock(block)}
                  <hr style={{margin: '2em 0'}} />
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale.locale,
    isFetching: state.collection.isFetching,
    collection: state.collection.collection,
    cardDesigns: state.product.products
  }
}

const mapDispatchToProps = {
  fetchCollection: (siteId, slug, locale) => fetchCollection(siteId, slug, locale)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollectionPage)));
