import {
  FETCH_CATALOGUE_REQUEST,
  FETCH_CATALOGUE_SUCCESS,
  FETCH_CATALOGUE_FAILURE
} from 'actions/ProductActions';

const initialState = {
  isFetching: false,
  products: []
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATALOGUE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case FETCH_CATALOGUE_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        products: action.products
      });
    }
    case FETCH_CATALOGUE_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
}
