import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { InputLabel, TextField, FormHelperText } from '@material-ui/core';

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.value || this.props.value === 0) {
      this.props.setValue(this.props.value, false);
    }
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.target.value);
  }

  render() {
    const { type, name, label, inlineLabel, disabled, multiline, inputLabelProps, inputProps, inputFieldProps,
            hint, onFocus, getValue, getErrorMessage, isPristine, variant, inputRef, style } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className={'input-container text-field' + (type == 'hidden' ? ' hidden' : '')} style={style}>
        {!inlineLabel && <InputLabel htmlFor={name} error={isError}>{label}</InputLabel>}
        <TextField
          error={isError}
          type={type || "text"}
          margin="dense"
          multiline={multiline}
          rows="4"
          id={name}
          name={name}
          onChange={this.changeValue}
          onFocus={onFocus}
          value={getValue() || ''}
          disabled={disabled}
          variant={variant || 'outlined'}
          label={inlineLabel && label}
          fullWidth
          InputLabelProps={inputLabelProps}
          inputProps={inputFieldProps}
          InputProps={inputProps}
          inputRef={inputRef}
        />
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError} className="input-error">{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText className="input-hint">{hint}</FormHelperText>
        }
      </div>
    );
  }
}

TextInput.propTypes = {
  ...propTypes
};

export default withFormsy(TextInput);
