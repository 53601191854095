import axios from 'axios';
import { flashMessage } from 'mui-redux-flash';
import { formatMessage } from 'utils/i18n';

export const FETCH_COLLECTION_GROUP_REQUEST = 'FETCH_COLLECTION_GROUP_REQUEST';
export const FETCH_COLLECTION_GROUP_SUCCESS = 'FETCH_COLLECTION_GROUP_SUCCESS';
export const FETCH_COLLECTION_GROUPS_SUCCESS = 'FETCH_COLLECTION_GROUPS_SUCCESS';
export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_GROUP_FAILURE = 'FETCH_COLLECTION_GROUP_FAILURE';

function fetchCollectionGroupRequest() {
  return {
    type: FETCH_COLLECTION_GROUP_REQUEST
  }
}

function fetchCollectionGroupSuccess(collectionGroup) {
  return {
    type: FETCH_COLLECTION_GROUP_SUCCESS,
    collectionGroup
  }
}

function fetchCollectionGroupsSuccess(collectionGroups) {
  return {
    type: FETCH_COLLECTION_GROUPS_SUCCESS,
    collectionGroups
  }
}

function fetchCollectionGroupFailure() {
  return {
    type: FETCH_COLLECTION_GROUP_FAILURE
  }
}

function fetchCollectionSuccess(collection) {
  return {
    type: FETCH_COLLECTION_SUCCESS,
    collection
  }
}

export function fetchCollectionGroups(siteId, name, locale) {
  return dispatch => {
    dispatch(fetchCollectionGroupRequest());
    return axios.get(`/sites/${siteId}/collection_groups`, {params: {locale: locale}})
      .then(response => {
        dispatch(fetchCollectionGroupsSuccess(response.data.groups));
      }).catch(error => {
        dispatch(fetchCollectionGroupFailure());
      });
  }
}

export function fetchCollectionGroup(siteId, name, locale) {
  return dispatch => {
    dispatch(fetchCollectionGroupRequest());
    return axios.get(`/sites/${siteId}/collection_groups/${name}`, {params: {locale: locale}})
      .then(response => {
        dispatch(fetchCollectionGroupSuccess(response.data.group));
      }).catch(error => {
        dispatch(fetchCollectionGroupFailure());
        dispatch(flashMessage(formatMessage({id: "collection.not_found", defaultMessage: "Category not found"}), {isError: true}));
      });
  }
}

export function fetchCollection(siteId, slug, locale) {
  return dispatch => {
    dispatch(fetchCollectionGroupRequest());
    return axios.get(`/sites/${siteId}/collections/${slug}`, {params: {locale: locale}})
      .then(response => {
        dispatch(fetchCollectionSuccess(response.data.collection));
      }).catch(error => {
        dispatch(fetchCollectionGroupFailure());
        dispatch(flashMessage(formatMessage({id: "collection.not_found", defaultMessage: "Category not found"}), {isError: true}));
      });
  }
}
