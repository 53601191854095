import axios from 'axios';

export const FETCH_SITE_REQUEST = 'FETCH_SITE_REQUEST';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';
export const FETCH_SITE_FAILURE = 'FETCH_SITE_FAILURE';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

function fetchSiteRequest() {
  return {
    type: FETCH_SITE_REQUEST
  }
}

function fetchSiteSuccess(site, config, cart) {
  return {
    type: FETCH_SITE_SUCCESS,
    site,
    config,
    cart
  }
}

function fetchSiteFailure() {
  return {
    type: FETCH_SITE_FAILURE
  }
}

export function fetchSite(id, locale) {
  return dispatch => {
    dispatch(fetchSiteRequest());
    return axios.get('/sites/' + id, {params: {locale: locale}})
      .then(response => {
        dispatch(fetchSiteSuccess(response.data.site, response.data.config, response.data.cart));
      }).catch(error => {
        dispatch(fetchSiteFailure());
      });
  }
}

export function changeLocale(locale) {
  return dispatch => {
    dispatch({type: CHANGE_LOCALE, locale: locale});
  }
}
