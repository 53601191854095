import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Drawer, List, ListItem, ListItemText, Divider, Typography,
         Badge, Button, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CartIcon from '@material-ui/icons/ShoppingCart';
import MediaQuery from 'react-responsive';
import { availableLocales, localeTitle } from 'utils/locales';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from "react-intl";

const styles = theme => ({
  appbar: {
    height: '5em'
  },
  toolbar: {
    paddingTop: '10px'
  },
  logo: {
    height: '60px',
    verticalAlign: 'middle'
  },
  logoMobile: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logoContainerMobile: {
    width: '100%',
    textAlign: 'center'
  },
  headerButton: {
    margin: '0px 16px',
    fontSize: '18px'
  },
  carouselItem: {
    position: 'relative'
  },
  carouselImage: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '14em'
    }
  },
  carouselContent: {
    position: 'absolute',
    top: '35%',
    left: '6em'
  },
  carouselTitle: {
    color: 'white',
    fontWeight: 'bold'
  },
  carouselDescription: {
    color: 'white'
  },
  carouselArrow: {
    position: 'absolute',
    zIndex: 2,
    width: 30,
    height: 30,
    top: 'calc(50% - 15px)',
    cursor: 'pointer',
    color: 'white',
    fontSize: '40px',
    transform: 'scale(1, 2)'
  },
  drawer: {
    width: '360px',
    backgroundColor: window.HEADER_BACKGROUND_COLOUR
  },
  cart: {
    cursor: 'pointer'
  },
  badge: {
    backgroundColor: window.CART_NUMBER_COLOUR
  },
  badgeExtra: {
    backgroundColor: window.CART_NUMBER_COLOUR,
    position: 'static'
  },
  localeMenu: {
    marginLeft: '20px'
  },
  localeImage: {
    marginRight: '10px',
    height: '18px',
    paddingBottom: '1px'
  }
});

const headerStyle = {color: window.HEADER_FONT_COLOUR};
const extraHeaderStyle = {color: window.EXTRA_HEADER_FONT_COLOUR};

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      localeMenuOpen: false
    };

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleLocaleMenuClick = this.handleLocaleMenuClick.bind(this);
    this.localeMenuAnchor = null;
  }

  handleDrawerOpen() {
    this.setState({open: true});
  };

  handleDrawerClose() {
    this.setState({open: false});
  };

  handleLocaleMenuClick(event) {
    this.setState({localeMenuOpen: true});
    this.localeMenuAnchor = event.currentTarget;
  };

  handleLocaleChange(locale) {
    this.setState({localeMenuOpen: false});
    this.props.changeLocale(locale);
  };

  render() {
    const { site, cart, locale, navigateTo, intl, classes } = this.props;

    return (
      <React.Fragment>
        {site.header_template == 'double_level' &&
          <div className="extra-header" style={{background: window.EXTRA_HEADER_BACKGROUND_COLOUR}}>
            <div style={{marginLeft: 'auto'}} />
            {!site.disable_balance_check &&
              <NavLink style={{marginRight: '20px'}} to='/balance' className='button-link'>
                {site.balance_icon &&
                  <img src={site.balance_icon} alt="Check Balance" />
                }
                <Button style={extraHeaderStyle}>
                  <FormattedMessage id="navigation.check_balance" defaultMessage="Check Balance"/>
                </Button>
              </NavLink>
            }
            <Badge badgeContent={cart.length} className={classes.cart} color="primary" classes={{badge: classes.badgeExtra}} onClick={() => navigateTo('/cart')}>
              {site.cart_icon ?
                <img src={site.cart_icon} />
              :
                <CartIcon style={extraHeaderStyle} titleAccess='Cart' />
              }
              <Typography style={extraHeaderStyle}>
                <FormattedMessage id="navigation.cart" defaultMessage="Basket" />
              </Typography>
            </Badge>
            <MediaQuery minWidth={948}>
              {site.extra_header_url && site.extra_header_url_text &&
                <a href={site.extra_header_url} target='_blank'>
                  <Typography style={extraHeaderStyle}>{site.extra_header_url_text}</Typography>
                </a>
              }
            </MediaQuery>
          </div>
        }
        <AppBar position='relative' color='default' className={classes.appbar} style={{background: window.HEADER_BACKGROUND_COLOUR}}>
          <Toolbar className='toolbar' className={classes.toolbar}>
            <MediaQuery maxWidth={948}>
              <MenuIcon onClick={this.handleDrawerOpen} titleAccess='Menu' />
              <div className={classes.logoContainerMobile}>
                <NavLink to='/' className='button-link' aria-label="Home">
                  <img src={site.logo} className={classes.logo} alt={site.page_title} />
                </NavLink>
              </div>
              {site.header_template == 'single_level' &&
                <Badge badgeContent={cart.length} className={classes.cart} color="primary" classes={{badge: classes.badge}} onClick={() => navigateTo('/cart')}>
                  <CartIcon titleAccess='Cart' />
                </Badge>
              }
            </MediaQuery>
            <MediaQuery minWidth={948}>
              <NavLink to='/' className='button-link' aria-label="Home">
                <img src={site.logo} className={classes.logo} alt={site.page_title} />
              </NavLink>
              {!site.hide_home_button &&
                <NavLink to='/' className='button-link' aria-label="Home">
                  <Button style={headerStyle} className={classes.headerButton}>
                    {site.home_button_text ?
                      site.home_button_text
                    :
                      <FormattedMessage id="navigation.buy_now" defaultMessage="Buy Now"/>
                    }
                  </Button>
                </NavLink>
              }
              {site.collection_groups.map((group, index) =>
                <NavLink to={group.direct_collection_link ? `/collections/${group.collection_slug}` : `/categories/${group.slug}`} className='button-link' key={index}>
                  <Button style={headerStyle} className={classes.headerButton}>
                    {group.label}
                  </Button>
                </NavLink>
              )}
              <div style={{marginLeft: 'auto'}} />
              {site.header_template == 'single_level' &&
                <React.Fragment>
                  {!site.disable_balance_check &&
                    <NavLink to='/balance' className='button-link'>
                      <Button style={headerStyle} className={classes.headerButton}>
                        <FormattedMessage id="navigation.check_balance" defaultMessage="Check Balance"/>
                      </Button>
                    </NavLink>
                  }
                  <Badge style={{marginLeft: site.disable_balance_check ? 'auto' : '20px'}}
                    badgeContent={cart.length} className={classes.cart} color="primary" classes={{badge: classes.badge}} onClick={() => navigateTo('/cart')}>
                    <CartIcon style={headerStyle} titleAccess='Cart' />
                  </Badge>
                </React.Fragment>
              }
              {site.locales.length > 1 &&
                <div className={classes.localeMenu}>
                  <Button aria-haspopup="true" aria-expanded={this.state.localeMenuOpen ? 'true' : undefined} onClick={this.handleLocaleMenuClick} className={classes.headerButton}>
                    <img className={classes.localeImage} src={`/locales/${locale}.jpg`} alt={locale} />
                    <FormattedMessage id={localeTitle(locale)} defaultMessage={locale}/>
                  </Button>
                  <Menu anchorEl={this.localeMenuAnchor} open={this.state.localeMenuOpen} onClose={() => this.setState({localeMenuOpen: false})}>
                    {availableLocales(site.locales).map(locale => (
                      <MenuItem key={locale.locale} value={locale.locale} onClick={() => this.handleLocaleChange(locale.locale)}>
                        <img className={classes.localeImage} src={`/locales/${locale.locale}.jpg`} alt={locale.locale} />
                        <FormattedMessage id={localeTitle(locale.locale)} defaultMessage={locale.locale}/>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              }
            </MediaQuery>
          </Toolbar>
        </AppBar>
        <Drawer variant="temporary"
                open={this.state.open}
                anchor="left"
                ModalProps={{onBackdropClick: this.handleDrawerClose}}
                classes={{paper: classes.drawer}}>
          <List>
            <ListItem button onClick={this.handleDrawerClose}>
              <img src={site.logo} className={classnames(classes.logo, classes.logoMobile)} style={{marginBottom: '2em'}} alt={site.page_title} />
            </ListItem>
            {!site.hide_home_button &&
              <React.Fragment>
                <Divider />
                <NavLink to='/' className='button-link'>
                  <ListItem button onClick={this.handleDrawerClose}>
                    <ListItemText primary={site.home_button_text ? site.home_button_text : intl.formatMessage({id: "navigation.buy_now", defaultMessage: "Buy Now"})}
                                  primaryTypographyProps={{variant: 'h6', style: headerStyle}} />
                  </ListItem>
                </NavLink>
              </React.Fragment>
            }
            <Divider />
            {site.collection_groups.map((group, index) =>
              <React.Fragment key={index}>
                <NavLink to={group.direct_collection_link ? `/collections/${group.collection_slug}` : `/categories/${group.slug}`} className='button-link'>
                  <ListItem button onClick={this.handleDrawerClose}>
                    <ListItemText primary={group.label} primaryTypographyProps={{variant: 'h6', style: headerStyle}} />
                  </ListItem>
                </NavLink>
                <Divider />
              </React.Fragment>
            )}
            {!site.disable_balance_check &&
              <React.Fragment>
                <NavLink to='/balance' className='button-link'>
                  <ListItem button onClick={this.handleDrawerClose}>
                    <ListItemText primary={intl.formatMessage({id: "navigation.check_balance", defaultMessage: "Check Balance"})}
                                  primaryTypographyProps={{variant: 'h6', style: headerStyle}} />
                  </ListItem>
                </NavLink>
                <Divider />
              </React.Fragment>
            }
            {site.locales.length > 1 &&
              <React.Fragment>
                <ListItem aria-haspopup="true" aria-expanded={this.state.localeMenuOpen ? 'true' : undefined} onClick={this.handleLocaleMenuClick}>
                  <img className={classes.localeImage} src={`/locales/${locale}.jpg`} alt={locale} />
                  <ListItemText primary={intl.formatMessage({id: localeTitle(locale), defaultMessage: locale})}
                                primaryTypographyProps={{variant: 'h6', style: headerStyle}} />
                </ListItem>
                <Menu anchorEl={this.localeMenuAnchor} open={this.state.localeMenuOpen} onClose={() => this.setState({localeMenuOpen: false})}>
                  {availableLocales(site.locales).map(locale => (
                    <MenuItem key={locale.locale} value={locale.locale} onClick={() => this.handleLocaleChange(locale.locale)}>
                      <img className={classes.localeImage} src={`/locales/${locale.locale}.jpg`} alt={locale.locale} />
                      <FormattedMessage id={localeTitle(locale.locale)} defaultMessage={locale.locale}/>
                    </MenuItem>
                  ))}
                </Menu>
                <Divider />
              </React.Fragment>
            }
          </List>
        </Drawer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(injectIntl(Header));
