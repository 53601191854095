import axios from 'axios';

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'FETCH_BALANCE_FAILURE';

function fetchBalanceRequest() {
  return {
    type: FETCH_BALANCE_REQUEST
  }
}

function fetchBalanceSuccess(balance, expiry) {
  return {
    type: FETCH_BALANCE_SUCCESS,
    balance,
    expiry
  }
}

function fetchBalanceFailure() {
  return {
    type: FETCH_BALANCE_FAILURE
  }
}

export function fetchBalance(data, form, recaptcha) {
  return dispatch => {
    dispatch(fetchBalanceRequest());
    return axios.post('/balance', data)
      .then(response => {
        recaptcha.current.reset();
        dispatch(fetchBalanceSuccess(response.data.balance, response.data.expiry_date));
      }).catch(error => {
        recaptcha.current.reset();
        dispatch(fetchBalanceFailure());
        if (error.response.data.error) {
          form.current.updateInputsWithError({card_number: error.response.data.error});
        }
      });
  }
}
