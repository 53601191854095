import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Typography, Button } from '@material-ui/core';
import renderHTML from 'react-render-html';
import CheckCircle from '@material-ui/icons/CheckCircle';
import green from '@material-ui/core/colors/green';

export class ThanksPage extends React.Component {
  componentDidMount() {
    if (!this.props.order_reference_number) {
      this.props.navigateTo('/');
    }
  }

  render() {
    const { site, order_reference_number, navigateTo } = this.props;
    if (!order_reference_number) {
      return null;
    }

    return (
      <div style={{textAlign: 'center'}}>
        <Typography variant="h4">Thanks for your order</Typography>
        <CheckCircle className="thanks-tick" style={{color: green[500], fontSize: 70, margin: '20px auto', display: 'block'}} />

        <Typography paragraph>Thank you for submitting your order. Your order number is</Typography>
        <Typography color="primary" variant="h5" paragraph>{order_reference_number}</Typography>
        <Typography paragraph>You will receive a confirmation email and receipt shortly.</Typography>

        {site.thanks_page_text &&
          <Typography component="span" color="textPrimary" paragraph className="top-space">
            {renderHTML(site.thanks_page_text)}
          </Typography>
        }

        <Button variant="contained" onClick={() => navigateTo('/')}>Return to store home</Button>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    site: state.site.site,
    order_reference_number: state.order.order_reference_number
  };
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
}

export default connect(mapStateToProps, mapDispatchToProps)(ThanksPage);
