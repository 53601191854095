import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import _ from 'lodash';
import Cards from 'react-credit-cards';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import { getMonthOptions, getYearOptions } from 'utils/dates';
import { currencyCode } from 'utils/locales';
import Notice from 'components/Notice';
import { cartTotal, deliveryFee } from 'utils/fees';
import { FormattedMessage, FormattedNumber } from "react-intl";
import store from 'utils/store';
import { flashMessage } from "mui-redux-flash";
import AdyenCheckout from '@adyen/adyen-web';
import { formatMessage } from 'utils/i18n';

const styles = {
  marginTop: {
    marginTop: '1em'
  }
};

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class PaymentStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_method: window.FONIX_ENABLED ? 'carrier_billing' : 'credit_card',
      credit_card_number: '',
      credit_card_name: '',
      credit_card_expiry_month: '',
      credit_card_expiry_year: '',
      credit_card_security_code: '',
      focused: 'number',
      adyenReady: false,
      paymentStarted: false
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAlternatePayment = this.handleAlternatePayment.bind(this);
    this.adyenDropIn = null;
    this.adyenDropinRef = React.createRef();
    this.handleAdyenReady = this.handleAdyenReady.bind(this);
    this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleAdyenCompleted = this.handleAdyenCompleted.bind(this);
    this.handleAdyenError = this.handleAdyenError.bind(this);
  }

  handleAdyenReady(){
    this.setState({adyenReady: true})
  }

  // There seems to be some sort of bug with Formsy library/Adyen combination,
  // where form is always invalid even though it isn't.
  // This is a HACK to ensure only when Adyen drop in is used, invalid form
  // submissions are directed to the right place
  handleInvalidSubmit() {
    if(this.state.payment_method == 'credit_card' && CC_GATEWAY == 'adyen') {
      this.handleSubmitPayment({});
    }
  }

  handleSubmitPayment(data, _resetForm, _invalidateForm) {
    if (this.state.payment_method == 'credit_card' && window.CC_GATEWAY == 'adyen') {
      // Adyen
      if (!this.adyenDropIn.isValid) {
        this.adyenDropIn.showValidation();
      }
      else {
        this.setState({paymentStarted: true})
        this.adyenDropIn.submit()
      }
    } else {
      // Anything else e.g. eWay, Fonix
      data.payment_method = this.state.payment_method;
      this.props.handleNext(data);
    }
  }

  handleFocus(event) {
    // Focus on either front (number) or back (CVC)
    const focused = event.target.name === 'credit_card_security_code' ? 'cvc' : 'number';
    this.setState({focused: focused});
  }

  handleChange(values) {
    delete values.payment_method; // We only want credit card fields
    // Map undefined values to empty strings so react-credit-cards doesn't throw warnings
    const mappedValues = _.mapValues(values, (v) => {return v || ''});
    this.setState(mappedValues);
  }

  handleAlternatePayment() {
    if (window.CC_GATEWAY == 'adyen') {
      this.props.handleNewPayment();
    }
    this.setState({payment_method: 'credit_card'})
  }

  handleAdyenCompleted(result, component) {
    const { handleNext, handleNewPayment, handlePolling } = this.props;

    this.setState({paymentStarted: false})
    if (result.resultCode === 'Authorised') {
      handlePolling();
    }
    else {
      store.dispatch(flashMessage(formatMessage({id: "checkout.error", defaultMessage: "Order could not be submitted, please try again"}), {isError: true}));
      this.setState({adyenReady: false})
      this.adyenDropIn.unmount();
      this.adyenDropIn = null;
      handleNewPayment();
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  handleAdyenError(error, component) {
    console.error(error.name, error.message, error.stack, component);
    this.setState({paymentStarted: false})
    store.dispatch(flashMessage(formatMessage({id: "checkout.payment.error", defaultMessage: "Error processing payment. Please try again."}), {isError: true}));
  }

  mountAdyen() {
    const { payment_config } = this.props;
    const ADYEN_CONFIG = {
      onPaymentCompleted: this.handleAdyenCompleted,
      onError: this.handleAdyenError
    };

    const createCheckout = async () => {
      const checkout = await AdyenCheckout(_.merge(ADYEN_CONFIG, payment_config));
      this.adyenDropIn = checkout.create('dropin', {onReady: this.handleAdyenReady});
      this.adyenDropIn.mount(this.adyenDropinRef.current);
    }
    createCheckout();
  }

  renderFormButtons(neutralAction, neutralTextId, neutralTextDefault) {
    const { isProcessing } = this.props;
    const { payment_method, adyenReady, paymentStarted } = this.state;

    return (
      <div className='button-container'>
        <Button variant='contained' onClick={neutralAction}>
          <FormattedMessage id={neutralTextId} defaultMessage={neutralTextDefault} />
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={payment_method === 'credit_card' && window.CC_GATEWAY == 'adyen' ? (!adyenReady || paymentStarted) : isProcessing}>
          <FormattedMessage id="checkout.payment.proceed" defaultMessage="Submit"/>
        </Button>
      </div>
    )
  }

  render() {
    const { handleNext, handleBack, isProcessing, classes, cart, site, config, payment_config,
            digitalOnly, deliveryOptionId, storedCardAmount, creditCardIssuer, handleCreditCardCallback } = this.props;
    const { payment_method, adyenReady, paymentStarted } = this.state;

    const delivery = digitalOnly ? 0 : deliveryFee(config.delivery_options, deliveryOptionId);
    var total = cartTotal(cart) + parseFloat(delivery) - parseFloat(storedCardAmount || 0);
    if (creditCardIssuer == 'amex') {
      total = (total * (100 + parseFloat(config.amex_fee_percentage)) / 100).toFixed(2);
    }
    if (payment_method === 'carrier_billing' && total > window.FONIX_MAX_AMOUNT) {
      // Disable carrier billing if total is over £40
      this.handleAlternatePayment();
    }

    if (!isProcessing && window.CC_GATEWAY == 'adyen' && !this.adyenDropIn && payment_config && payment_method == 'credit_card') {
      this.mountAdyen();
    }

    return (
      <div className="center">
        <Formsy noValidate onValidSubmit={this.handleSubmitPayment} onInvalidSubmit={this.handleInvalidSubmit} onChange={this.handleChange}>
          <Grid container spacing={8} justify="center" align="left">
            <Grid item xs={12} md={8}>
              <Typography variant="h5" paragraph>
                <FormattedMessage id="checkout.payment.enter_payment" defaultMessage="Enter your payment details"/>
              </Typography>
              <Typography variant="h5" className="inline-block">
                <FormattedMessage id="order.total" defaultMessage="Total"/>
              </Typography>
              <Typography variant="h5" className="inline-block bold" style={{marginLeft: '20px', color: window.THEME_PRIMARY_COLOUR}}>
                <FormattedNumber value={total} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
              </Typography>
              <hr style={{marginBottom: '30px'}} />

              {payment_method === 'carrier_billing' &&
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <div className="carrier-billing-container">
                      <Typography variant="h5">Add charge to your mobile bill?</Typography>
                      <Typography style={{marginTop: '1em'}}>Please enter your mobile phone number to pay through carrier billing with Fonix. You will then receive an SMS to confirm payment</Typography>
                      <TextInput type="number" name="phone_number" label="Mobile Number" required
                        validationErrors={requiredValidation}
                      />

                      {this.renderFormButtons(this.handleAlternatePayment, 'checkout.payment.no_thanks', 'No thanks!')}
                    </div>
                  </Grid>
                </Grid>
              }
              {payment_method === 'credit_card' &&
                <React.Fragment>
                  {window.CC_GATEWAY == 'eway' ?
                    <React.Fragment>
                      <Cards
                        number={this.state.credit_card_number || ''}
                        name={this.state.credit_card_name || ''}
                        expiry={this.state.credit_card_expiry_month + String(this.state.credit_card_expiry_year).slice(-2)}
                        cvc={this.state.credit_card_security_code}
                        focused={this.state.focused || ''}
                        callback={handleCreditCardCallback}
                      />

                      {creditCardIssuer === 'amex' &&
                        <div className={classes.marginTop}>
                          <Notice text={`There is a ${config.amex_fee_percentage}% surcharge for American Express card transactions. The new total you will be charged is $${total}`} />
                        </div>
                      }
                      <Grid container spacing={8}>
                        <Grid item xs={12}>
                          <TextInput type="number" name="credit_card_number" label="Credit Card Number" required
                            onFocus={this.handleFocus}
                            validationErrors={requiredValidation}
                          />

                          <TextInput name="credit_card_name" label="Credit Card Name" required
                            onFocus={this.handleFocus}
                            validationErrors={requiredValidation}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <SelectInput name="credit_card_expiry_month" label="Expiry Month" required
                            onChange={this.handleFocus}
                            placeholder="MM"
                            options={getMonthOptions()}
                            validations="futureMonth:credit_card_expiry_year"
                            validationErrors={{
                              isDefaultRequiredValue: "can't be blank",
                              futureMonth: 'must be in future'
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectInput name="credit_card_expiry_year" label="Expiry Year" required
                            onChange={this.handleFocus}
                            placeholder="YYYY"
                            options={getYearOptions()}
                            validations="futureYear"
                            validationErrors={{
                              isDefaultRequiredValue: "can't be blank",
                              futureYear: 'must be in future'
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextInput type="number" name="credit_card_security_code" label="Card Security Code" required
                            hint="This is the 3/4 digit code found on the signature panel of your card"
                            onFocus={this.handleFocus}
                            validations={{
                              minLength: 3,
                              maxLength: 4
                            }}
                            validationErrors={_.merge(requiredValidation, {
                              minLength: 'must be 3-4 digits',
                              maxLength: 'must be 3-4 digits'
                            })}
                          />
                        </Grid>
                      </Grid>
                      { /* eWAY site seal */ }
                      <div id='eWAYBlock'>
                        <div style={{textAlign: 'center', marginTop: '1em'}}>
                          <a href='https://www.eway.com.au/secure-site-seal?i=13&se=3&theme=0' title='eWAY Payment Gateway' target='_blank' rel='nofollow'>
                            <img alt='eWAY Payment Gateway' src='https://www.eway.com.au/developer/payment-code/verified-seal.php?img=13&size=3&theme=0' />
                          </a>
                        </div>
                      </div>
                    </React.Fragment>
                  :
                    <React.Fragment>
                      <div ref={this.adyenDropinRef}></div>
                      {!adyenReady &&
                        <div align="center">
                          <CircularProgress />
                        </div>
                      }
                    </React.Fragment>
                  }
                  <Typography color='textSecondary' align="center" style={{marginTop: '1em'}}>
                    <FormattedMessage id="checkout.payment.international_cards" defaultMessage="International credit cards will not be accepted."/>
                  </Typography>
                </React.Fragment>
              }
            </Grid>
          </Grid>

          {payment_method !== 'carrier_billing' &&
            this.renderFormButtons(handleBack, 'checkout.payment.back', 'Back')
          }
        </Formsy>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isProcessing: state.order.isProcessing,
    payment_config: state.order.payment_config,
    order_reference: state.order.order_reference_number
  };
}

export default withStyles(styles)(connect(mapStateToProps, {})(PaymentStep));
