import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import classnames from 'classnames';

const styles = theme => ({
  logoBanner: {
    width: '100%',
    padding: '1em',
    textAlign: 'center',
    background: LOGO_BANNER_BACKGROUND_COLOUR
  },
  imageContainer: {
    height: '4em'
  },
  textContainer: {
    height: '6em',
    paddingTop: '1.5em'
  },
  headerLabel: {
    display: 'inline-block',
    fontSize: '1em',
    color: LOGO_BANNER_FONT_COLOUR,
    position: 'relative',
  },
  headerLabelImage: {
    top: '-0.5em'
  },
  headerLabelText: {
    marginRight: '26px',
    verticalAlign: 'middle'
  },
  bannerLogo: {
    height: '2em',
    marginLeft: '2em'
  },
  buttonText: {
    color: LOGO_BANNER_FONT_COLOUR,
    fontSize: '1em',
    display: 'inline-block',
    margin: '0px 26px'
  },
  divider: {
    width: '75%',
    marginBottom: '1em'
  }
});

export class LogoBanner extends React.Component {
  render() {
    const { site, classes } = this.props;

    return (
      site.banner_logos.length > 0 &&
        <div className={classnames(classes.logoBanner, site.logo_banner_style == 'image' ? classes.imageContainer : classes.textContainer)}>
          {site.logo_banner_text &&
            <Typography className={classnames(classes.headerLabel, site.logo_banner_style == 'image' ? classes.headerLabelImage : classes.headerLabelText)}>
              {site.logo_banner_text}
            </Typography>
          }
          {site.logo_banner_style == 'image' && site.banner_logos.map((bannerLogo, index) =>
            <NavLink key={index} to={'/collections/' + bannerLogo.collection_slug} className='button-link'>
              <img key={index} src={bannerLogo.logo} className={classes.bannerLogo} alt={bannerLogo.name} />
            </NavLink>
          )}
          {site.logo_banner_style == 'text' &&
            <React.Fragment>
              {site.banner_logos.map((bannerLogo, index) =>
                <NavLink key={index} to={'/collections/' + bannerLogo.collection_slug} className='button-link'>
                  <Button className={classes.buttonText}>
                    {bannerLogo.name}
                  </Button>
                </NavLink>
              )}
              <hr className={classes.divider} />
            </React.Fragment>
          }
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    site: state.site.site
  }
}

export default withStyles(styles)(connect(mapStateToProps, null)(LogoBanner));
