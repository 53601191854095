import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Formsy from 'formsy-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { commonTheme } from 'utils/commonStyles';
import FileInput from 'components/inputs/FileInput';
import { confirmUpload } from 'actions/OrderActions';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.confirmUpload(_.merge(data, {reference_number: this.props.match.params.reference_number}));
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>

        <Grid container spacing={8} align='center'>
          <Grid item xs={12} md={12}>
            <Formsy onValidSubmit={this.handleSubmit} className={classes.form}>
              <Grid container spacing={8} justify='center'>
                <Grid item xs={12} md={8}>
                  <Typography>Please upload requested documents here</Typography>
                  <FileInput name='image_blob_key' buttonLabel='Choose File' required />
                </Grid>
              </Grid>
              <div className='button-container'>
                <Button type='submit' variant='contained' color='primary'>Confirm</Button>
              </div>
            </Formsy>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  confirmUpload: (data) => confirmUpload(data)
}

export default withStyles(commonTheme)(connect(null, mapDispatchToProps)(ConfirmationPage));
