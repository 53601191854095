import store from 'utils/store';
import { createIntl, createIntlCache } from 'react-intl';
import { selectMessages } from 'utils/locales';

export function formatMessage(options) {
  const locale = store.getState().locale.locale;

  const intl = createIntl({
    locale: locale,
    messages: selectMessages(locale)
  }, createIntlCache());

  return intl.formatMessage(options);
}
