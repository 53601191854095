import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { FormHelperText } from '@material-ui/core';

class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.changeValue = this.changeValue.bind(this);
    this.defaultDate = this.defaultDate.bind(this);

    this.props.setValue(this.defaultDate());
  }

  changeValue(event) {
    this.props.setValue(event.format());
  }

  defaultDate() {
    if (this.props.minDate) {
      return this.props.minDate;
    }
    return new Date(new Date().setHours(0,0,0,0));
  }

  render() {
    const { name, label, hint, minDate, maxDate, getErrorMessage, isPristine, getValue } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            label={label}
            value={getValue() || this.defaultDate()}
            onChange={this.changeValue}
            minDate={minDate}
            maxDate={maxDate}
            fullWidth
          />
        </MuiPickersUtilsProvider>

        {!isPristine() && errorMessage &&
          <FormHelperText error={isError} className="input-error">{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    )
  }
}

DateInput.propTypes = {
  ...propTypes
};

export default withFormsy(DateInput);
