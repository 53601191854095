import { CHANGE_LOCALE } from 'actions/SiteActions';
import { supportedLocales } from 'utils/locales';

const initialState = {
  locale: supportedLocales.includes(navigator.language.slice(0,2)) ? navigator.language.slice(0,2) : 'en'
};

export default function locale(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE: {
      return Object.assign({}, state, {
        locale: action.locale,
      });
    }
    default:
      return state;
  }
}
