import axios from 'axios';
import { flashMessage } from 'mui-redux-flash';
import { formatMessage } from 'utils/i18n';

export function createSupportLog(data, captchaResponse, callback) {
  return dispatch => {
    return axios.post('/support_logs', {support_log: data, captcha_response: captchaResponse})
      .then(response => {
        callback();
      }).catch(error => {
        dispatch(flashMessage(formatMessage({id: "contact.error", defaultMessage: "Your request was unsuccessful. Please try again later"}), {isError: true}));
      });
  }
}
