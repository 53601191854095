import React from 'react';
import Formsy from 'formsy-react';
import _ from 'lodash';
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { currencyCode } from 'utils/locales';
import { cartTotal } from 'utils/fees';
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";

export class StoredCardStep extends React.Component {
  constructor(props) {
    super(props);

    this.requiredValidation = { isDefaultRequiredValue: props.intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})};
    this.handleStoredCardSubmit = this.handleStoredCardSubmit.bind(this);
    this.formRef = React.createRef();
  }

  handleStoredCardSubmit(data) {
    const { validateStoredCard, handleStoredCardSubmit } = this.props;

    validateStoredCard(data, handleStoredCardSubmit, this.formRef);
  }

  render() {
    const { handleNext, handleNextClear, site, cart, formData, intl } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={16} justify="center" className="stored-card-step">
          <Grid item xs={12} md={4}>
            <Typography color="primary" variant="h6" paragraph>
              <FormattedMessage id="checkout.stored_card.use_stored_card" defaultMessage="Pay with your {name} card" values={{name: site.closed_loop_name}}/>
            </Typography>

            {formData.stored_card_amount ?
              <React.Fragment>
                <table className="to-pay-table">
                  <tbody>
                    <tr>
                      <td>
                        <Typography color="primary" variant="h4">
                          <FormattedNumber value={parseFloat(formData.stored_card_amount)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
                        </Typography>
                      </td>
                      <td><Typography><FormattedMessage id="checkout.stored_card.credit" defaultMessage="Credit" /></Typography></td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="h4">
                          <FormattedNumber value={parseFloat(cartTotal(cart) - formData.stored_card_amount)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
                        </Typography>
                      </td>
                      <td><Typography><FormattedMessage id="checkout.stored_card.to_pay" defaultMessage="To pay" /></Typography></td>
                    </tr>
                  </tbody>
                </table>
                <Button variant='contained' color="primary" onClick={handleNext} style={{marginTop: '1em'}}>
                  <FormattedMessage id="checkout.stored_card.pay" defaultMessage="Pay"/>
                </Button>
              </React.Fragment>
            :
              <Formsy onValidSubmit={this.handleStoredCardSubmit} className='full-width' ref={this.formRef}>
                <TextInput name="stored_card_number"
                           label={site.closed_loop_name + ' ' + intl.formatMessage({id: "checkout.confirm.stored_card_number", defaultMessage: "Card Number"})}
                           required
                  validationErrors={this.requiredValidation}
                />

                <TextInput name="pin"
                           label={intl.formatMessage({id: "checkout.confirm.stored_card_pin", defaultMessage: "PIN"})}
                           required
                  validations="isLength:4"
                  validationErrors={_.merge(this.requiredValidation, {
                    isLength: intl.formatMessage({id: "checkout.confirm.stored_card_pin_invalid", defaultMessage: "must be 4 digits"})
                  })}
                />
                <Button type='submit' variant='contained' style={{marginTop: '1em'}}>
                  <FormattedMessage id="checkout.confirm.stored_card_submit" defaultMessage="Submit"/>
                </Button>
              </Formsy>
            }

          </Grid>
          <Grid item align="center" xs={12} md={2}>
            <Typography color="primary" variant="h6" paragraph>Or</Typography>
            <div className="vertical-line" />
          </Grid>
          <Grid item xs={12} md={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button variant="contained" color="primary" onClick={() => handleNextClear({stored_card_number: null, stored_card_amount: null})}>
              <FormattedMessage id="checkout.stored_card.other_payment_options" defaultMessage="Other payment options"/>
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default injectIntl(StoredCardStep);
