import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

export function init() {
  if (window.GA_TRACKING_ID) {
    ReactGA.initialize(window.GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  if (window.FB_PIXEL_ID) {
    ReactPixel.init(window.FB_PIXEL_ID);
  }

  if (window.TIKTOK_PIXEL_ID) {
    TiktokPixel.init(window.TIKTOK_PIXEL_ID);
  }
}

export function pageView(path) {
  if (window.GA_TRACKING_ID) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  if (window.FB_PIXEL_ID) {
    ReactPixel.pageView();
  }

  if (window.TIKTOK_PIXEL_ID) {
    TiktokPixel.pageView();
  }

  if (path == '/checkout') {
    checkout();
  }
}

export function viewContent(description) {
  if (window.GTAG_TRACKING_ID) {
    gtag("event", "view_item", {
      items: [{item_name: description}]
    });
  }

  if (window.FB_PIXEL_ID) {
    ReactPixel.track('ViewContent', {description});
  }

  if (window.TIKTOK_PIXEL_ID) {
    TiktokPixel.track('ViewContent', {description});
  }
}

export function addToCart(params) {
  if (window.GTAG_TRACKING_ID) {
    gtag("event", "add_to_cart", {
      currency: params.currency,
      value: params.value,
      items: [{item_id: params.card_design_id}]
    });
  }

  if (window.FB_PIXEL_ID) {
    ReactPixel.track('AddToCart', {quantity: params.quantity, value: params.value, currency: params.currency});
  }

  if (window.TIKTOK_PIXEL_ID) {
    TiktokPixel.track('AddToCart', {quantity: params.quantity, value: params.value, currency: params.currency});
  }
}

export function checkout() {
  if (window.FB_PIXEL_ID) {
    ReactPixel.track('InitiateCheckout');
  }

  if (window.TIKTOK_PIXEL_ID) {
    TiktokPixel.track('InitiateCheckout');
  }
}

export function purchase(params) {
  if (window.GTAG_TRACKING_ID) {
    gtag("event", "purchase", {
      currency: params.currency,
      value: params.value,
      items: _.map(params.cart || [], (i) => { return { item_id: i.card_design_id } })
    });
  }

  if (window.FB_PIXEL_ID) {
    ReactPixel.track('Purchase', {value: params.value, currency: params.currency});
  }

  if (window.TIKTOK_PIXEL_ID) {
    TiktokPixel.track('PlaceAnOrder', {value: params.value, currency: params.currency});
  }
}
