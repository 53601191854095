import axios from 'axios';

export function validateStoredCard(data, callback, form) {
  return dispatch => {
    return axios.post('/validations/stored_card', data)
      .then(response => {
        callback({stored_card_number: data.stored_card_number, stored_card_pin: data.pin, stored_card_amount: response.data.balance});
      }).catch(error => {
        form.current.updateInputsWithError(error.response.data.errors);
        callback({stored_card_number: '', stored_card_pin: '', stored_card_amount: 0});
      });
  }
}
