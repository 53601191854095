import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'mui-redux-flash';
import { formatMessage } from 'utils/i18n';
import { addToCart as trackAddToCart } from 'utils/tracking';
import { currencyCode } from 'utils/locales';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const CLEAR_CART_REQUEST = 'CLEAR_CART_REQUEST';
export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAILURE = 'CLEAR_CART_FAILURE';
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'REMOVE_FROM_CART_FAILURE';

function addToCartRequest() {
  return {
    type: ADD_TO_CART_REQUEST
  }
}

function addToCartSuccess(cart) {
  return {
    type: ADD_TO_CART_SUCCESS,
    cart
  }
}

function addToCartFailure() {
  return {
    type: ADD_TO_CART_FAILURE
  }
}

function clearCartRequest() {
  return {
    type: CLEAR_CART_REQUEST
  }
}

export function clearCartSuccess() {
  return {
    type: CLEAR_CART_SUCCESS
  }
}

function clearCartFailure() {
  return {
    type: CLEAR_CART_FAILURE
  }
}

function removeFromCartRequest() {
  return {
    type: REMOVE_FROM_CART_REQUEST
  }
}

function removeFromCartSuccess(cart) {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    cart
  }
}

function removeFromCartFailure() {
  return {
    type: REMOVE_FROM_CART_FAILURE
  }
}

export function addToCart(item) {
  return dispatch => {
    dispatch(addToCartRequest());
    return axios.put('/cart', {item})
      .then(response => {
        dispatch(addToCartSuccess(response.data.cart));
        trackAddToCart({quantity: item.quantity || 1, value: item.amount, currency: currencyCode(), card_design_id: item.card_design_id});
        dispatch(push('/cart'));
      }).catch(error => {
        dispatch(flashMessage(error.response.data.error, {isError: true}));
        dispatch(addToCartFailure());
      });
  }
}

export function clearCart() {
  return dispatch => {
    dispatch(clearCartRequest());
    return axios.delete('/cart')
      .then(response => {
        dispatch(clearCartSuccess());
        dispatch(push('/'));
      }).catch(error => {
        dispatch(clearCartFailure());
      });
  }
}

export function removeFromCart(item_id) {
  return dispatch => {
    dispatch(removeFromCartRequest());
    return axios.put('/cart', {item_id})
      .then(response => {
        dispatch(removeFromCartSuccess(response.data.cart));
        dispatch(flashMessage(formatMessage({id: "card.card_removed", defaultMessage: "Card successfully removed"})));
      }).catch(error => {
        dispatch(removeFromCartFailure());
      });
  }
}
