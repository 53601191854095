import React from 'react';
import { Helmet } from "react-helmet";
import { Typography } from '@material-ui/core';
import renderHTML from 'react-render-html';
import { appendSeoBrand } from 'utils/seo'

export default class CustomPage extends React.Component {
  render() {
    const { content, name, page_title, description, block_search_engines } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand(page_title)}</title>
          <meta name="description" content={description} />
          <meta name="robots" content={window.APP_ENV === 'production' && !block_search_engines ? 'index' : 'noindex'} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Typography variant='h5' component="h1" align='center'>{name}</Typography>
        <Typography variant='subtitle1'>{content && renderHTML(content)}</Typography>
      </React.Fragment>
    )
  }
}
