import { addValidationRule } from 'formsy-react';

addValidationRule('isGreaterThan', function (_values, value, compareValue) {
  return Number(value) > compareValue;
});

addValidationRule('isLessThan', function (_values, value, compareValue) {
  return Number(value) < compareValue;
});

addValidationRule('isGreaterEqualTo', function (_values, value, compareValue) {
  return Number(value) >= compareValue;
});

addValidationRule('isLessEqualTo', function (_values, value, compareValue) {
  return Number(value) <= compareValue;
});

// Validate month is in future with corresponding year field. Useful for credit card expiries
addValidationRule('futureMonth', function (values, value, yearField) {
  if (!values[yearField]) return true;

  var futureYear = values[yearField] > (new Date()).getFullYear();
  var futureMonth = (values[yearField] == (new Date()).getFullYear()) && value >= (new Date()).getMonth() + 1;
  return futureYear || futureMonth;
});

addValidationRule('futureYear', function (values, value) {
  return value >= (new Date()).getFullYear();
});
