import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';

const styles = theme => ({
  container: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '10px',
    cursor: 'pointer'
  },
  expand: {
    float: 'right'
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
});

export class Expandable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: props.expanded || false
    };
  }

  render() {
    const { children, classes, title } = this.props;

    return (
      <React.Fragment>
        <div className={classes.container} onClick={() => this.setState({expanded: !this.state.expanded})}>
          <Typography variant='body1' style={{display: 'inline-block', padding: '12px 0'}}><strong>{title}</strong></Typography>
          <IconButton className={classnames(classes.expand, {[classes.expandOpen]: this.state.expanded})}>
            <ExpandMoreIcon style={{fontSize: '30px'}} />
          </IconButton>
        </div>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Expandable);
