import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import { pollTransaction } from 'actions/OrderActions';
import {FormattedMessage, injectIntl} from "react-intl";

export class PollTransactionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false
    };

    this.flagError = this.flagError.bind(this);
  }

  flagError() {
    this.setState({error: true});
  }

  componentDidMount() {
    // Keep polling transaction until we get a result one way or another
    // clearInterval() is called inside pollTransaction() so no need to worry about stopping it here at all
    var intervalId = setInterval(() => {
      this.props.pollTransaction(this.props.match.params.id, intervalId, this.flagError, this.props.cart);
    }, 3000);
  }

  render() {
    const { error } = this.state;

    return (
      <div className="center">
        {error ?
          <React.Fragment>
            <Typography variant="h5" style={{marginTop: '1em'}}>
              <FormattedMessage id="processing.error" defaultMessage="There was a problem with your payment"/>
            </Typography>
            <ErrorIcon style={{color: red[500], fontSize: 70, margin: '20px auto', display: 'block'}} />

            <Button variant="contained" onClick={() => this.props.navigateTo('/cart')}>
              <FormattedMessage id="processing.back_to_cart" defaultMessage="Return to cart"/>
            </Button>
          </React.Fragment>
        :
          <React.Fragment>
            <Typography variant="h5" paragraph style={{marginTop: '1em'}}>
              <FormattedMessage id="processing.title" defaultMessage="Processing order, please wait"/>
            </Typography>
            <CircularProgress color="primary" size={50} />
          </React.Fragment>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart.cart
  };
}

const mapDispatchToProps = {
  pollTransaction: (id, intervalId, callback, cart) => pollTransaction(id, intervalId, callback, cart),
  navigateTo: (path) => push(path)
}

export default connect(mapStateToProps, mapDispatchToProps)(PollTransactionPage);
