import store from 'utils/store';

export function appendSeoBrand(title) {
  if (!store.getState().site.site) {
    return title;
  }

  const seo_brand = store.getState().site.site.seo_brand;
  if (!seo_brand) {
    return title;
  }
  if (!title) {
    return seo_brand;
  }
  return title + ' | ' + seo_brand
}