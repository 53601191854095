import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  CLEAR_CART_REQUEST,
  CLEAR_CART_SUCCESS,
  CLEAR_CART_FAILURE,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAILURE
} from 'actions/CartActions';
import { FETCH_SITE_SUCCESS } from 'actions/SiteActions';
import { SUBMIT_ORDER_SUCCESS } from 'actions/OrderActions';

const initialState = {
  cart: [],
  isProcessing: false
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART_REQUEST:
    case REMOVE_FROM_CART_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case ADD_TO_CART_SUCCESS:
    case REMOVE_FROM_CART_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        cart: action.cart
      });
    }
    case ADD_TO_CART_FAILURE:
    case REMOVE_FROM_CART_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case CLEAR_CART_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case CLEAR_CART_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        cart: []
      });
    }
    case CLEAR_CART_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case FETCH_SITE_SUCCESS: {
      return Object.assign({}, state, {
        cart: action.cart
      });
    }
    default:
      return state;
  }
}
