import React from 'react';
import { currencyCode } from 'utils/locales';
import { FormattedMessage, FormattedNumber } from 'react-intl';

export function galleryBadgeText(promotion) {
  if (promotion.promotion_type == 'percentage_discount') {
    return (
      <React.Fragment>
        <FormattedNumber value={promotion.percentage_discount} style='percent'
                         minimumFractionDigits={(promotion.percentage_discount * 100) % 1 === 0 ? '0' : '2'} />
        <FormattedMessage id="order.percentage_off" defaultMessage=" off" />
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'value_discount') {
    return (
      <React.Fragment>
        <FormattedNumber value={promotion.value_discount} style='currency'
                         currency={currencyCode()} currencyDisplay='narrowSymbol'
                         minimumFractionDigits={promotion.value_discount % 1 === 0 ? '0' : '2'} />
        <FormattedMessage id="order.percentage_off" defaultMessage=" off" />
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'buy_one_get_one_free') {
    return <FormattedMessage id="order.free_gift" defaultMessage="Free gift" />;
  } else if (promotion.promotion_type == 'percentage_uplift') {
    return (
      <React.Fragment>
        <FormattedNumber value={promotion.percentage_uplift} style='percent'
                         minimumFractionDigits={(promotion.percentage_uplift * 100) % 1 === 0 ? '0' : '2'} />
        <FormattedMessage id="order.percentage_extra" defaultMessage=" extra" />
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'value_uplift') {
    return (
      <React.Fragment>
        <FormattedNumber value={promotion.value_uplift} style='currency'
                         currency={currencyCode()} currencyDisplay='narrowSymbol'
                         minimumFractionDigits={promotion.value_uplift % 1 === 0 ? '0' : '2'} />
        <FormattedMessage id="order.percentage_extra" defaultMessage=" extra" />
      </React.Fragment>
    );
  }
}

export function purchaseDisplay(promotion, card_value, intl) {
  if (promotion.promotion_type == 'percentage_discount') {
    return (
      <React.Fragment>
        {galleryBadgeText(promotion)}*
        {card_value > 0 ?
          '. ' +
          intl.formatMessage({id: "order.saving", defaultMessage: "Saving "}) +
          intl.formatNumber(card_value * promotion.percentage_discount,
            {style: "currency", currency: currencyCode(), currencyDisplay: 'narrowSymbol',
             minimumFractionDigits: (card_value * promotion.percentage_discount) % 1 === 0 ? '0' : '2'}) +
          "!"
          : ''
        }
        <br />
        {card_value > 0 &&
          <React.Fragment>
            <FormattedMessage id="order.you_pay" defaultMessage="You pay " />
            <FormattedNumber value={card_value - (card_value * promotion.percentage_discount)}
                             style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'
                             minimumFractionDigits={(card_value - (card_value * promotion.percentage_discount)) * 100 % 1 === 0 ? '0' : '2'} />
          </React.Fragment>
        }
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'value_discount') {
    return (
      <React.Fragment>
        {galleryBadgeText(promotion)}*
        {card_value > 0 &&
          <React.Fragment>
            <br />
            <FormattedMessage id="order.you_pay" defaultMessage="You pay " />
            <FormattedNumber value={card_value - promotion.value_discount}
                             style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'
                             minimumFractionDigits={(card_value - promotion.value_discount) % 1 === 0 ? '0' : '2'} />
          </React.Fragment>
        }
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'buy_one_get_one_free') {
    return (
      <React.Fragment>
        <FormattedMessage id="order.buy_one_get_one_free" defaultMessage="Buy one get one free"
                          values={{product: promotion.free_card_design.name, value: intl.formatNumber(promotion.value, {style: "currency", currency: currencyCode(), currencyDisplay: 'narrowSymbol'})}}
        />
        *
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'percentage_uplift') {
    return (
      <React.Fragment>
        {galleryBadgeText(promotion)}*
        {card_value > 0 &&
          <React.Fragment>
            <br />
            <FormattedMessage id="order.you_pay" defaultMessage="You pay " />
            <FormattedNumber value={card_value}
                             style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'
                             minimumFractionDigits={card_value % 1 === 0 ? '0' : '2'} />
            <FormattedMessage id="order.for" defaultMessage=" for " />
            <FormattedNumber value={parseFloat(card_value) + (card_value * promotion.percentage_uplift)}
                             style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'
                             minimumFractionDigits={(card_value + (card_value * promotion.percentage_uplift)) % 1 === 0 ? '0' : '2'} />
          </React.Fragment>
        }
      </React.Fragment>
    );
  } else if (promotion.promotion_type == 'value_uplift') {
    return (
      <React.Fragment>
        {galleryBadgeText(promotion)}*
        {card_value > 0 &&
          <React.Fragment>
            <br />
            <FormattedMessage id="order.you_pay" defaultMessage="You pay " />
            <FormattedNumber value={card_value}
                             style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'
                             minimumFractionDigits={card_value % 1 === 0 ? '0' : '2'} />
            <FormattedMessage id="order.for" defaultMessage=" for " />
            <FormattedNumber value={parseFloat(card_value) + parseFloat(promotion.value_uplift)}
                             style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'
                             minimumFractionDigits={(card_value + promotion.value_uplift) % 1 === 0 ? '0' : '2'} />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}
