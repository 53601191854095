import {
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_FAILURE
} from 'actions/BalanceActions';

const initialState = {
  isFetching: false,
  balance: null,
  expiryDate: null
}

export default function balance(state = initialState, action) {
  switch (action.type) {
    case FETCH_BALANCE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_BALANCE_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        balance: action.balance,
        expiryDate: action.expiry
      });
    }
    case FETCH_BALANCE_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        balance: null,
        expiryDate: null
      });
    }
    default:
      return state;
  }
}
