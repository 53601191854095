import moment from 'moment';

// 0 padded month numbers
export function getMonthOptions() {
  return moment.months().map(m => moment().month(m).format('MM'))
}

// Next 10 years
export function getYearOptions() {
  var years = [];
  var startYear = (new Date().getFullYear());

  for (var i = 0; i < 10; i++) {
    years.push(startYear + i);
  }
  return years.map(y => y.toString());
}
