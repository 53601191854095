import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Router from 'components/Router';
import ScrollToTop from 'components/ScrollToTop';
import store, { history } from 'utils/store';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { buttonStyle } from 'utils/commonStyles';
import I18n from 'utils/locales';

class App extends React.Component {
  getMuiTheme() {
    return createMuiTheme({
      palette: {
        primary: {main: window.THEME_PRIMARY_COLOUR ? '#' + window.THEME_PRIMARY_COLOUR : indigo[500]},
        secondary: {main: window.THEME_SECONDARY_COLOUR ? '#' + window.THEME_SECONDARY_COLOUR : pink[500]}
      },
      typography: {
        fontFamily: window.CUSTOM_FONT,
        useNextVariants: true
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              backgroundColor: window.BACKGROUND_COLOUR || '#fafafa',
            }
          }
        },
        MuiButton: {
          root: {
            borderRadius: buttonStyle(window.BUTTON_STYLE),
            textTransform: 'initial',
            padding: window.BUTTON_STYLE == 'rounded' ? '12px 30px' : '12px 22px'
          },
          containedPrimary: {
            backgroundColor: window.PRIMARY_BUTTON_COLOUR ? '#' + window.PRIMARY_BUTTON_COLOUR : indigo[500],
            color: window.PRIMARY_BUTTON_FONT_COLOUR ? '#' + window.PRIMARY_BUTTON_FONT_COLOUR : 'white',
            '&:hover': {
              backgroundColor: window.PRIMARY_BUTTON_COLOUR ? lighten('#'+window.PRIMARY_BUTTON_COLOUR, 0.3) : indigo[800]
            }
          }
        },
        MuiInput: {
          underline: {
            '&:before': {
              borderBottom: window.BODY_FONT_COLOUR != '#' ? '1px solid ' + window.BODY_FONT_COLOUR : '1px solid rgba(0, 0, 0, 0.42)'
            }
          }
        },
        MuiTablePagination: {
          toolbar: {
            justifyContent: 'center'
          },
          spacer: {
            flex: 'none'
          },
          caption: {
            fontSize: '1rem'
          }
        }
      }
    })
  }

  render() {
    return (
      <Provider store={store}>
        <I18n>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <ScrollToTop>
                <Router />
              </ScrollToTop>
            </MuiThemeProvider>
          </ConnectedRouter>
        </I18n>
      </Provider>
    )
  }
}

export default App;
