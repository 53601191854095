import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { currencyCode } from 'utils/locales';
import { fetchOrder, verifyOrder } from 'actions/OrderActions';
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";

export class VerifyPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchOrder(this.props.match.params.id);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.verifyOrder(this.props.order.reference_number, data, this.formRef);
  }

  render() {
    const { order, isFetching, isProcessing, intl } = this.props;

    if (isFetching) {
      return <CircularProgress color="inherit" size={50} />;
    }

    return (
      order &&
        <div className="center">
          <Typography variant="h4" style={{marginBottom: '1em'}}>
            <FormattedMessage id="verify.title" defaultMessage="Complete Payment"/>
          </Typography>

          <Typography variant="h5" paragraph>
            <FormattedNumber value={parseFloat(order.amount)} style='currency' currency={currencyCode()} currencyDisplay='narrowSymbol'/>
          </Typography>
          <Typography paragraph>
            <FormattedMessage id="verify.mobile_number" defaultMessage="Mobile number"/> {order.phone_number}
          </Typography>
          <Typography paragraph>
            <FormattedMessage id="verify.enter_token" defaultMessage="Enter the token below to complete your purchase"/>
          </Typography>

          <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef} className="full-width">
            <Grid container spacing={8} justify="center">
              <Grid item md={6} xs={12}>
                <TextInput name="token"
                           label={intl.formatMessage({id: "navigation.verify.token", defaultMessage: "Token"})}
                           required
                  validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})}}
                />
              </Grid>
            </Grid>
            <Typography paragraph style={{textAlign: 'center', marginTop: '2em'}}>Payment will be charged to your mobile bill</Typography>

            <div className='button-container'>
              <Button type='submit' variant='contained' color='primary' disabled={isProcessing}>
                <FormattedMessage id="verify.proceed" defaultMessage="Pay Now"/>
              </Button>
            </div>
          </Formsy>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    order: state.order.order,
    isFetching: state.order.isFetching,
    isProcessing: state.order.isProcessing
  };
}

const mapDispatchToProps = {
  fetchOrder: (id) => fetchOrder(id),
  verifyOrder: (id, data, formRef) => verifyOrder(id, data, formRef)
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VerifyPage));
