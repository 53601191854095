import {
  FETCH_COLLECTION_GROUP_REQUEST,
  FETCH_COLLECTION_GROUP_SUCCESS,
  FETCH_COLLECTION_GROUPS_SUCCESS,
  FETCH_COLLECTION_GROUP_FAILURE,
  FETCH_COLLECTION_SUCCESS
} from 'actions/CollectionActions';

const initialState = {
  isFetching: false,
  collection: null,
  collectionGroup: null,
  collectionGroups: []
};

export default function collection(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLECTION_GROUP_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case FETCH_COLLECTION_GROUP_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        collectionGroup: action.collectionGroup
      });
    }
    case FETCH_COLLECTION_GROUPS_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        collectionGroups: action.collectionGroups
      });
    }
    case FETCH_COLLECTION_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        collection: action.collection
      });
    }
    case FETCH_COLLECTION_GROUP_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
}
