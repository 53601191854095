import React from 'react';
import { Typography, Grid, Icon } from '@material-ui/core';
import renderHTML from 'react-render-html';
import Expandable from 'components/Expandable';

export default function renderContentBlock(block) {
  const type = block.template;

  if (type === 'text') {
    return (
      <Typography align="left" component="span">{renderHTML(block.content)}</Typography>
    );
  } else if (type === 'two_columns') {
    return(
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Typography variant="h4" align="left">{block.title}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="left" component="span">{renderHTML(block.content)}</Typography>
        </Grid>
      </Grid>
    );
  } else if (type == 'video') {
    return (
      <React.Fragment>
        <Typography variant="h5" className="video-title" gutterBottom>{block.title}</Typography>
        {block.image &&
          <img src={block.image} className="video-image" />
        }
        <iframe src={block.video_embed_link} title="Video player"
          frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
          style={{width: '100%', aspectRatio: '16/9', marginTop: '1em'}}
        />
      </React.Fragment>
    );
  } else if (type == 'accordion') {
    return (
      block.items.map((item, index) =>
        <Expandable key={index} title={item.label}>
          <Typography align="left" component="span">{renderHTML(item.content)}</Typography>
        </Expandable>
      )
    );
  } else if (type == 'icons') {
    return (
      <Grid container justify="center" spacing={8}>
        {block.items.map((item, index) =>
          <Grid key={index} item xs={2}>
            <div className="content-icon-container">
              <Icon className="outlined" style={{fontSize: '40px'}}>{item.icon}</Icon>
            </div>
            <Typography>{item.label}</Typography>
          </Grid>
        )}
      </Grid>
    );
  } else if (type == 'gallery') {
    return (
      <Grid container justify="flex-start" spacing={40}>
        {block.items.map((item, index) =>
          <Grid key={index} item xs={12} md={6}>
            <div className="gallery-container">
              <Typography align="left" variant="h6">{item.label}</Typography>
              <div className="gallery-image-container">
                <img src={item.image} className="gallery-image" />
              </div>
              {item.content &&
                <Typography align="left" component="span">{renderHTML(item.content)}</Typography>
              }
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}
