import _ from 'lodash';

export const cartTotal = function(cart) {
  return _.reduce( cart, (sum, lineItem) => sum + (parseFloat(lineItem.total) || (parseFloat(lineItem.amount) + parseFloat(lineItem.card_fee)) * lineItem.quantity), 0 );
}

export const totalSavings = function(cart) {
  return _.reduce( cart, (sum, lineItem) => sum + parseFloat(lineItem.promotion_savings || 0), 0 );
}

export const totalBonus = function(cart) {
  return _.reduce( cart, (sum, lineItem) => sum + parseFloat(lineItem.promotion_bonus || 0  ), 0 );
}

export const deliveryFee = function(deliveryOptions, deliveryOptionId) {
  return _.find(deliveryOptions, {id: parseInt(deliveryOptionId)}).amount;
}
