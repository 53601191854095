import _ from 'lodash';

export function getCountry(code) {
  return _.find(COUNTRIES, function(c) { return c["code"] == code });
}

export function getCountries() {
  return COUNTRIES.map(c => ({value: c["code"], label: c["name"]}));
}

const COUNTRIES = [
  {
    "code": "AF",
    "name": "Afghanistan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AL",
    "name": "Albania",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "DZ",
    "name": "Algeria",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AS",
    "name": "American Samoa",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AD",
    "name": "Andorra",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AO",
    "name": "Angola",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AI",
    "name": "Anguilla",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AQ",
    "name": "Antarctica",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AG",
    "name": "Antigua and Barbuda",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AR",
    "name": "Argentina",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "C",
        "name": "Ciudad Aut\u00f3noma de Buenos Aires"
      },
      {
        "code": "B",
        "name": "Buenos Aires"
      },
      {
        "code": "K",
        "name": "Catamarca"
      },
      {
        "code": "H",
        "name": "Chaco"
      },
      {
        "code": "U",
        "name": "Chubut"
      },
      {
        "code": "X",
        "name": "C\u00f3rdoba"
      },
      {
        "code": "W",
        "name": "Corrientes"
      },
      {
        "code": "E",
        "name": "Entre R\u00edos"
      },
      {
        "code": "P",
        "name": "Formosa"
      },
      {
        "code": "Y",
        "name": "Jujuy"
      },
      {
        "code": "L",
        "name": "La Pampa"
      },
      {
        "code": "F",
        "name": "La Rioja"
      },
      {
        "code": "M",
        "name": "Mendoza"
      },
      {
        "code": "N",
        "name": "Misiones"
      },
      {
        "code": "Q",
        "name": "Neuqu\u00e9n"
      },
      {
        "code": "R",
        "name": "R\u00edo Negro"
      },
      {
        "code": "A",
        "name": "Salta"
      },
      {
        "code": "J",
        "name": "San Juan"
      },
      {
        "code": "D",
        "name": "San Luis"
      },
      {
        "code": "Z",
        "name": "Santa Cruz"
      },
      {
        "code": "S",
        "name": "Santa Fe"
      },
      {
        "code": "G",
        "name": "Santiago del Estero"
      },
      {
        "code": "V",
        "name": "Tierra del Fuego"
      },
      {
        "code": "T",
        "name": "Tucum\u00e1n"
      }
    ]
  },
  {
    "code": "AM",
    "name": "Armenia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AW",
    "name": "Aruba",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AU",
    "name": "Australia",
    "hasPostalCodes": true,
    "requiresState": true,
    "subdivisions": [
      {
        "code": "ACT",
        "name": "Australian Capital Territory"
      },
      {
        "code": "NSW",
        "name": "New South Wales"
      },
      {
        "code": "NT",
        "name": "Northern Territory"
      },
      {
        "code": "QLD",
        "name": "Queensland"
      },
      {
        "code": "SA",
        "name": "South Australia"
      },
      {
        "code": "TAS",
        "name": "Tasmania"
      },
      {
        "code": "VIC",
        "name": "Victoria"
      },
      {
        "code": "WA",
        "name": "Western Australia"
      }
    ]
  },
  {
    "code": "AT",
    "name": "Austria",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "9",
        "name": "Wien"
      },
      {
        "code": "3",
        "name": "Nieder\u00f6sterreich"
      },
      {
        "code": "4",
        "name": "Ober\u00f6sterreich"
      },
      {
        "code": "5",
        "name": "Salzburg"
      },
      {
        "code": "2",
        "name": "K\u00e4rnten"
      },
      {
        "code": "6",
        "name": "Steiermark"
      },
      {
        "code": "7",
        "name": "Tirol"
      },
      {
        "code": "1",
        "name": "Burgenland"
      },
      {
        "code": "8",
        "name": "Vorarlberg"
      }
    ]
  },
  {
    "code": "AZ",
    "name": "Azerbaijan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BS",
    "name": "Bahamas",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BH",
    "name": "Bahrain",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BD",
    "name": "Bangladesh",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BB",
    "name": "Barbados",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BY",
    "name": "Belarus",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BE",
    "name": "Belgium",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BZ",
    "name": "Belize",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BJ",
    "name": "Benin",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BM",
    "name": "Bermuda",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BT",
    "name": "Bhutan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BO",
    "name": "Bolivia",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius and Saba",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BA",
    "name": "Bosnia and Herzegovina",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BW",
    "name": "Botswana",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BV",
    "name": "Bouvet Island",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BR",
    "name": "Brazil",
    "hasPostalCodes": true,
    "requiresState": true,
    "subdivisions": [
      {
        "code": "AC",
        "name": "Acre"
      },
      {
        "code": "AL",
        "name": "Alagoas"
      },
      {
        "code": "AP",
        "name": "Amap\u00e1"
      },
      {
        "code": "AM",
        "name": "Amazonas"
      },
      {
        "code": "BA",
        "name": "Bahia"
      },
      {
        "code": "CE",
        "name": "Cear\u00e1"
      },
      {
        "code": "DF",
        "name": "Distrito Federal"
      },
      {
        "code": "ES",
        "name": "Esp\u00edrito Santo"
      },
      {
        "code": "GO",
        "name": "Goi\u00e1s"
      },
      {
        "code": "MA",
        "name": "Maranh\u00e3o"
      },
      {
        "code": "MT",
        "name": "Mato Grosso"
      },
      {
        "code": "MS",
        "name": "Mato Grosso do Sul"
      },
      {
        "code": "MG",
        "name": "Minas Gerais"
      },
      {
        "code": "PA",
        "name": "Par\u00e1"
      },
      {
        "code": "PB",
        "name": "Para\u00edba"
      },
      {
        "code": "PR",
        "name": "Paran\u00e1"
      },
      {
        "code": "PE",
        "name": "Pernambuco"
      },
      {
        "code": "PI",
        "name": "Piau\u00ed"
      },
      {
        "code": "RJ",
        "name": "Rio de Janeiro"
      },
      {
        "code": "RN",
        "name": "Rio Grande do Norte"
      },
      {
        "code": "RS",
        "name": "Rio Grande do Sul"
      },
      {
        "code": "RO",
        "name": "Rond\u00f4nia"
      },
      {
        "code": "RR",
        "name": "Roraima"
      },
      {
        "code": "SC",
        "name": "Santa Catarina"
      },
      {
        "code": "SP",
        "name": "S\u00e3o Paulo"
      },
      {
        "code": "SE",
        "name": "Sergipe"
      },
      {
        "code": "TO",
        "name": "Tocantins"
      }
    ]
  },
  {
    "code": "IO",
    "name": "British Indian Ocean Territory",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BN",
    "name": "Brunei Darussalam",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BG",
    "name": "Bulgaria",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BF",
    "name": "Burkina Faso",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "BI",
    "name": "Burundi",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KH",
    "name": "Cambodia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CM",
    "name": "Cameroon",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CA",
    "name": "Canada",
    "hasPostalCodes": true,
    "requiresState": true,
    "subdivisions": [
      {
        "code": "AB",
        "name": "Alberta"
      },
      {
        "code": "BC",
        "name": "British Columbia"
      },
      {
        "code": "MB",
        "name": "Manitoba"
      },
      {
        "code": "NL",
        "name": "Newfoundland and Labrador"
      },
      {
        "code": "NB",
        "name": "New Brunswick"
      },
      {
        "code": "NS",
        "name": "Nova Scotia"
      },
      {
        "code": "NT",
        "name": "Northwest Territories"
      },
      {
        "code": "NU",
        "name": "Nunavut"
      },
      {
        "code": "ON",
        "name": "Ontario"
      },
      {
        "code": "PE",
        "name": "Prince Edward Island"
      },
      {
        "code": "QC",
        "name": "Quebec"
      },
      {
        "code": "SK",
        "name": "Saskatchewan"
      },
      {
        "code": "YT",
        "name": "Yukon Territory"
      }
    ]
  },
  {
    "code": "CV",
    "name": "Cape Verde",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KY",
    "name": "Cayman Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CF",
    "name": "Central African Republic",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TD",
    "name": "Chad",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CL",
    "name": "Chile",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "AN",
        "name": "Antofagasta"
      },
      {
        "code": "AP",
        "name": "Arica & Parinacota"
      },
      {
        "code": "AT",
        "name": "Atacama"
      },
      {
        "code": "AI",
        "name": "Ays\u00e9n"
      },
      {
        "code": "BI",
        "name": "Biob\u00edo"
      },
      {
        "code": "CO",
        "name": "Coquimbo"
      },
      {
        "code": "AR",
        "name": "La Araucan\u00eda"
      },
      {
        "code": "LL",
        "name": "Los Lagos"
      },
      {
        "code": "LR",
        "name": "Los R\u00edos"
      },
      {
        "code": "MA",
        "name": "Magallanes"
      },
      {
        "code": "ML",
        "name": "Maule"
      },
      {
        "code": "MM",
        "name": "Metropolitana"
      },
      {
        "code": "NB",
        "name": "\u00d1uble"
      },
      {
        "code": "LI",
        "name": "O\u2019Higgins"
      },
      {
        "code": "TA",
        "name": "Tarapac\u00e1"
      },
      {
        "code": "VS",
        "name": "Valparaiso"
      }
    ]
  },
  {
    "code": "CN",
    "name": "China",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CX",
    "name": "Christmas Island",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CC",
    "name": "Cocos (Keeling) Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CO",
    "name": "Colombia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KM",
    "name": "Comoros",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CG",
    "name": "Congo",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CD",
    "name": "Congo, the Democratic Republic of the",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CK",
    "name": "Cook Islands",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CR",
    "name": "Costa Rica",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CI",
    "name": "Cote d'Ivoire",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "HR",
    "name": "Croatia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CW",
    "name": "Cura\u00e7ao",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CY",
    "name": "Cyprus",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CZ",
    "name": "Czech Republic",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "DK",
    "name": "Denmark",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "DJ",
    "name": "Djibouti",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "DM",
    "name": "Dominica",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "DO",
    "name": "Dominican Republic",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "EC",
    "name": "Ecuador",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "EG",
    "name": "Egypt",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SV",
    "name": "El Salvador",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GQ",
    "name": "Equatorial Guinea",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ER",
    "name": "Eritrea",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "EE",
    "name": "Estonia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ET",
    "name": "Ethiopia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "FK",
    "name": "Falkland Islands (Malvinas)",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "FO",
    "name": "Faroe Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "FJ",
    "name": "Fiji",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "FI",
    "name": "Finland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "FR",
    "name": "France",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GF",
    "name": "French Guiana",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PF",
    "name": "French Polynesia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TF",
    "name": "French Southern Territories",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GA",
    "name": "Gabon",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GM",
    "name": "Gambia",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GE",
    "name": "Georgia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "DE",
    "name": "Germany",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "NI",
        "name": "Niedersachsen"
      },
      {
        "code": "BW",
        "name": "Baden-W\u00fcrttemberg"
      },
      {
        "code": "BY",
        "name": "Bayern"
      },
      {
        "code": "BE",
        "name": "Berlin"
      },
      {
        "code": "BB",
        "name": "Brandenburg"
      },
      {
        "code": "HB",
        "name": "Bremen"
      },
      {
        "code": "HH",
        "name": "Hamburg"
      },
      {
        "code": "HE",
        "name": "Hessen"
      },
      {
        "code": "MV",
        "name": "Mecklenburg-Vorpommern"
      },
      {
        "code": "NW",
        "name": "Nordrhein-Westfalen"
      },
      {
        "code": "RP",
        "name": "Rheinland-Pfalz"
      },
      {
        "code": "SL",
        "name": "Saarland"
      },
      {
        "code": "SN",
        "name": "Sachsen"
      },
      {
        "code": "ST",
        "name": "Sachsen-Anhalt"
      },
      {
        "code": "SH",
        "name": "Schleswig-Holstein"
      },
      {
        "code": "TH",
        "name": "Th\u00fcringen"
      }
    ]
  },
  {
    "code": "GH",
    "name": "Ghana",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GI",
    "name": "Gibraltar",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GR",
    "name": "Greece",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GL",
    "name": "Greenland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GD",
    "name": "Grenada",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GP",
    "name": "Guadeloupe",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GU",
    "name": "Guam",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GT",
    "name": "Guatemala",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GG",
    "name": "Guernsey",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GN",
    "name": "Guinea",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GW",
    "name": "Guinea-Bissau",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "GY",
    "name": "Guyana",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "HT",
    "name": "Haiti",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "HM",
    "name": "Heard Island and Mcdonald Islands",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VA",
    "name": "Holy See (Vatican City State)",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "HN",
    "name": "Honduras",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "HK",
    "name": "Hong Kong",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "HU",
    "name": "Hungary",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "IS",
    "name": "Iceland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "IN",
    "name": "India",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "AP",
        "name": "Andhra Pradesh"
      },
      {
        "code": "AR",
        "name": "Arunachal Pradesh"
      },
      {
        "code": "AS",
        "name": "Assam"
      },
      {
        "code": "BR",
        "name": "Bihar"
      },
      {
        "code": "CT",
        "name": "Chhattisgarh"
      },
      {
        "code": "GA",
        "name": "Goa"
      },
      {
        "code": "GJ",
        "name": "Gujarat"
      },
      {
        "code": "HR",
        "name": "Haryana"
      },
      {
        "code": "HP",
        "name": "Himachal Pradesh"
      },
      {
        "code": "JK",
        "name": "Jammu & Kashmir"
      },
      {
        "code": "JH",
        "name": "Jharkhand"
      },
      {
        "code": "KA",
        "name": "Karnataka"
      },
      {
        "code": "KL",
        "name": "Kerala"
      },
      {
        "code": "MP",
        "name": "Madhya Pradesh"
      },
      {
        "code": "MH",
        "name": "Maharashtra"
      },
      {
        "code": "MN",
        "name": "Manipur"
      },
      {
        "code": "ML",
        "name": "Meghalaya"
      },
      {
        "code": "MZ",
        "name": "Mizoram"
      },
      {
        "code": "NL",
        "name": "Nagaland"
      },
      {
        "code": "OR",
        "name": "Orissa"
      },
      {
        "code": "PB",
        "name": "Punjab"
      },
      {
        "code": "RJ",
        "name": "Rajasthan"
      },
      {
        "code": "SK",
        "name": "Sikkim"
      },
      {
        "code": "TN",
        "name": "Tamil Nadu"
      },
      {
        "code": "TR",
        "name": "Tripura"
      },
      {
        "code": "UT",
        "name": "Uttarakhand"
      },
      {
        "code": "UP",
        "name": "Uttar Pradesh"
      },
      {
        "code": "WB",
        "name": "West Bengal"
      },
      {
        "code": "AN",
        "name": "Andaman and Nicobar Islands"
      },
      {
        "code": "CH",
        "name": "Chandigarh"
      },
      {
        "code": "DN",
        "name": "Dadra and Nagar Haveli"
      },
      {
        "code": "DD",
        "name": "Daman & Diu"
      },
      {
        "code": "LD",
        "name": "Lakshadweep"
      },
      {
        "code": "DL",
        "name": "Delhi"
      },
      {
        "code": "PY",
        "name": "Pondicherry"
      },
      {
        "code": "TS",
        "name": "Telangana"
      }
    ]
  },
  {
    "code": "ID",
    "name": "Indonesia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "BA",
        "name": "Bali"
      },
      {
        "code": "BB",
        "name": "Bangka Belitung"
      },
      {
        "code": "BT",
        "name": "Banten"
      },
      {
        "code": "BE",
        "name": "Bengkulu"
      },
      {
        "code": "AC",
        "name": "Daista Aceh"
      },
      {
        "code": "JK",
        "name": "Jakarta"
      },
      {
        "code": "SU",
        "name": "Sumatera Utara"
      },
      {
        "code": "SB",
        "name": "Sumatera Barat"
      },
      {
        "code": "RI",
        "name": "Riau"
      },
      {
        "code": "JA",
        "name": "Jambi"
      },
      {
        "code": "SS",
        "name": "Sumatera Selatan"
      },
      {
        "code": "LA",
        "name": "Lampung"
      },
      {
        "code": "JB",
        "name": "Jawa Barat"
      },
      {
        "code": "JT",
        "name": "Jawa Tengah"
      },
      {
        "code": "YO",
        "name": "Daista Yogyakarta"
      },
      {
        "code": "JI",
        "name": "Jawa Timur"
      },
      {
        "code": "KB",
        "name": "Kalimantan Barat"
      },
      {
        "code": "KT",
        "name": "Kalimantan Tengah"
      },
      {
        "code": "KI",
        "name": "Kalimantan Timur"
      },
      {
        "code": "KS",
        "name": "Kalimantan Selatan"
      },
      {
        "code": "NB",
        "name": "Nusa Tenggara Barat"
      },
      {
        "code": "NT",
        "name": "Nusa Tenggara Timur"
      },
      {
        "code": "SN",
        "name": "Sulawesi Selatan"
      },
      {
        "code": "ST",
        "name": "Sulawesi Tengah"
      },
      {
        "code": "SA",
        "name": "Sulawesi Utara"
      },
      {
        "code": "SG",
        "name": "Sulawesi Tenggara"
      },
      {
        "code": "MA",
        "name": "Maluku"
      },
      {
        "code": "MU",
        "name": "Maluku Utara"
      },
      {
        "code": "IJ",
        "name": "Irian Jaya Timur"
      },
      {
        "code": "IT",
        "name": "Irian Jaya Tengah"
      },
      {
        "code": "IB",
        "name": "Irian Jawa Barat"
      },
      {
        "code": "GO",
        "name": "Gorontalo"
      }
    ]
  },
  {
    "code": "IQ",
    "name": "Iraq",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "IE",
    "name": "Ireland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "CW",
        "name": "Carlow"
      },
      {
        "code": "CN",
        "name": "Cavan"
      },
      {
        "code": "CE",
        "name": "Clare"
      },
      {
        "code": "C",
        "name": "Cork"
      },
      {
        "code": "DL",
        "name": "Donegal"
      },
      {
        "code": "D",
        "name": "Dublin"
      },
      {
        "code": "G",
        "name": "Galway"
      },
      {
        "code": "KY",
        "name": "Kerry"
      },
      {
        "code": "KE",
        "name": "Kildare"
      },
      {
        "code": "KK",
        "name": "Kilkenny"
      },
      {
        "code": "LS",
        "name": "Laois"
      },
      {
        "code": "LM",
        "name": "Leitrim"
      },
      {
        "code": "LK",
        "name": "Limerick"
      },
      {
        "code": "LD",
        "name": "Longford"
      },
      {
        "code": "LH",
        "name": "Louth"
      },
      {
        "code": "MO",
        "name": "Mayo"
      },
      {
        "code": "MH",
        "name": "Meath"
      },
      {
        "code": "MN",
        "name": "Monaghan"
      },
      {
        "code": "OY",
        "name": "Offaly"
      },
      {
        "code": "RN",
        "name": "Roscommon"
      },
      {
        "code": "SO",
        "name": "Sligo"
      },
      {
        "code": "TA",
        "name": "Tipperary"
      },
      {
        "code": "WD",
        "name": "Waterford"
      },
      {
        "code": "WH",
        "name": "Westmeath"
      },
      {
        "code": "WX",
        "name": "Wexford"
      },
      {
        "code": "WW",
        "name": "Wicklow"
      }
    ]
  },
  {
    "code": "IM",
    "name": "Isle of Man",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "IL",
    "name": "Israel",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "IT",
    "name": "Italy",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "JM",
    "name": "Jamaica",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "JP",
    "name": "Japan",
    "hasPostalCodes": true,
    "requiresState": true,
    "subdivisions": [
      {
        "code": "JP-23",
        "name": "Aichi"
      },
      {
        "code": "JP-05",
        "name": "Akita"
      },
      {
        "code": "JP-02",
        "name": "Aomori"
      },
      {
        "code": "JP-12",
        "name": "Chiba"
      },
      {
        "code": "JP-38",
        "name": "Ehime"
      },
      {
        "code": "JP-18",
        "name": "Fukui"
      },
      {
        "code": "JP-40",
        "name": "Fukuoka"
      },
      {
        "code": "JP-07",
        "name": "Fukushima"
      },
      {
        "code": "JP-21",
        "name": "Gifu"
      },
      {
        "code": "JP-10",
        "name": "Gunma"
      },
      {
        "code": "JP-34",
        "name": "Hiroshima"
      },
      {
        "code": "JP-01",
        "name": "Hokkaido"
      },
      {
        "code": "JP-28",
        "name": "Hy\u014dgo"
      },
      {
        "code": "JP-08",
        "name": "Ibaraki"
      },
      {
        "code": "JP-17",
        "name": "Ishikawa"
      },
      {
        "code": "JP-03",
        "name": "Iwate"
      },
      {
        "code": "JP-37",
        "name": "Kagawa"
      },
      {
        "code": "JP-46",
        "name": "Kagoshima"
      },
      {
        "code": "JP-14",
        "name": "Kanagawa"
      },
      {
        "code": "JP-39",
        "name": "K\u014dchi"
      },
      {
        "code": "JP-43",
        "name": "Kumamoto"
      },
      {
        "code": "JP-26",
        "name": "Ky\u014dto"
      },
      {
        "code": "JP-24",
        "name": "Mie"
      },
      {
        "code": "JP-04",
        "name": "Miyagi"
      },
      {
        "code": "JP-45",
        "name": "Miyazaki"
      },
      {
        "code": "JP-20",
        "name": "Nagano"
      },
      {
        "code": "JP-42",
        "name": "Nagasaki"
      },
      {
        "code": "JP-29",
        "name": "Nara"
      },
      {
        "code": "JP-15",
        "name": "Niigata"
      },
      {
        "code": "JP-44",
        "name": "\u014cita"
      },
      {
        "code": "JP-33",
        "name": "Okayama"
      },
      {
        "code": "JP-47",
        "name": "Okinawa"
      },
      {
        "code": "JP-27",
        "name": "\u014csaka"
      },
      {
        "code": "JP-41",
        "name": "Saga"
      },
      {
        "code": "JP-11",
        "name": "Saitama"
      },
      {
        "code": "JP-25",
        "name": "Shiga"
      },
      {
        "code": "JP-32",
        "name": "Shimane"
      },
      {
        "code": "JP-22",
        "name": "Shizuoka"
      },
      {
        "code": "JP-09",
        "name": "Tochigi"
      },
      {
        "code": "JP-36",
        "name": "Tokushima"
      },
      {
        "code": "JP-13",
        "name": "T\u014dky\u014d"
      },
      {
        "code": "JP-31",
        "name": "Tottori"
      },
      {
        "code": "JP-16",
        "name": "Toyama"
      },
      {
        "code": "JP-30",
        "name": "Wakayama"
      },
      {
        "code": "JP-06",
        "name": "Yamagata"
      },
      {
        "code": "JP-35",
        "name": "Yamaguchi"
      },
      {
        "code": "JP-19",
        "name": "Yamanashi"
      }
    ]
  },
  {
    "code": "JE",
    "name": "Jersey",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "JO",
    "name": "Jordan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KZ",
    "name": "Kazakhstan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KE",
    "name": "Kenya",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KI",
    "name": "Kiribati",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KR",
    "name": "Korea, Republic of",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KW",
    "name": "Kuwait",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KG",
    "name": "Kyrgyzstan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LA",
    "name": "Lao People's Democratic Republic",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LV",
    "name": "Latvia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LB",
    "name": "Lebanon",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LS",
    "name": "Lesotho",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LR",
    "name": "Liberia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LY",
    "name": "Libya",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LI",
    "name": "Liechtenstein",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LT",
    "name": "Lithuania",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LU",
    "name": "Luxembourg",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MO",
    "name": "Macao",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MG",
    "name": "Madagascar",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MW",
    "name": "Malawi",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MY",
    "name": "Malaysia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "01",
        "name": "Johor"
      },
      {
        "code": "02",
        "name": "Kedah"
      },
      {
        "code": "03",
        "name": "Kelantan"
      },
      {
        "code": "04",
        "name": "Melaka"
      },
      {
        "code": "05",
        "name": "Negeri Sembilan"
      },
      {
        "code": "06",
        "name": "Pahang"
      },
      {
        "code": "07",
        "name": "Pulau Pinang"
      },
      {
        "code": "08",
        "name": "Perak"
      },
      {
        "code": "09",
        "name": "Perlis"
      },
      {
        "code": "10",
        "name": "Selangor"
      },
      {
        "code": "11",
        "name": "Terengganu"
      },
      {
        "code": "12",
        "name": "Sabah"
      },
      {
        "code": "13",
        "name": "Sarawak"
      },
      {
        "code": "14",
        "name": "Kuala Lumpur"
      },
      {
        "code": "15",
        "name": "Labuan"
      },
      {
        "code": "16",
        "name": "Putrajaya"
      }
    ]
  },
  {
    "code": "MV",
    "name": "Maldives",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ML",
    "name": "Mali",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MT",
    "name": "Malta",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MH",
    "name": "Marshall Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MQ",
    "name": "Martinique",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MR",
    "name": "Mauritania",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MU",
    "name": "Mauritius",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "YT",
    "name": "Mayotte",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MX",
    "name": "Mexico",
    "hasPostalCodes": true,
    "requiresState": true,
    "subdivisions": [
      {
        "code": "AGU",
        "name": "Aguascalientes"
      },
      {
        "code": "BCN",
        "name": "Baja California"
      },
      {
        "code": "BCS",
        "name": "Baja California Sur"
      },
      {
        "code": "CAM",
        "name": "Campeche"
      },
      {
        "code": "COA",
        "name": "Coahuila"
      },
      {
        "code": "COL",
        "name": "Colima"
      },
      {
        "code": "CHP",
        "name": "Chiapas"
      },
      {
        "code": "CHH",
        "name": "Chihuahua"
      },
      {
        "code": "DUR",
        "name": "Durango"
      },
      {
        "code": "GUA",
        "name": "Guanajuato"
      },
      {
        "code": "GRO",
        "name": "Guerrero"
      },
      {
        "code": "HID",
        "name": "Hidalgo"
      },
      {
        "code": "JAL",
        "name": "Jalisco"
      },
      {
        "code": "CMX",
        "name": "Ciudad de M\u00e9xico"
      },
      {
        "code": "MEX",
        "name": "M\u00e9xico"
      },
      {
        "code": "MIC",
        "name": "Michoac\u00e1n"
      },
      {
        "code": "MOR",
        "name": "Morelos"
      },
      {
        "code": "NAY",
        "name": "Nayarit"
      },
      {
        "code": "NLE",
        "name": "Nuevo Le\u00f3n"
      },
      {
        "code": "OAX",
        "name": "Oaxaca"
      },
      {
        "code": "PUE",
        "name": "Puebla"
      },
      {
        "code": "QUE",
        "name": "Quer\u00e9taro"
      },
      {
        "code": "ROO",
        "name": "Quintana Roo"
      },
      {
        "code": "SLP",
        "name": "San Luis Potos\u00ed"
      },
      {
        "code": "SIN",
        "name": "Sinaloa"
      },
      {
        "code": "SON",
        "name": "Sonora"
      },
      {
        "code": "TAB",
        "name": "Tabasco"
      },
      {
        "code": "TAM",
        "name": "Tamaulipas"
      },
      {
        "code": "TLA",
        "name": "Tlaxcala"
      },
      {
        "code": "VER",
        "name": "Veracruz"
      },
      {
        "code": "YUC",
        "name": "Yucat\u00e1n"
      },
      {
        "code": "ZAC",
        "name": "Zacatecas"
      }
    ]
  },
  {
    "code": "FM",
    "name": "Micronesia, Federated States of",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MD",
    "name": "Moldova, Republic of",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MC",
    "name": "Monaco",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MN",
    "name": "Mongolia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ME",
    "name": "Montenegro",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MS",
    "name": "Montserrat",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MA",
    "name": "Morocco",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MZ",
    "name": "Mozambique",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MM",
    "name": "Myanmar",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "07",
        "name": "Ayeyarwady"
      },
      {
        "code": "02",
        "name": "Bago"
      },
      {
        "code": "14",
        "name": "Chin"
      },
      {
        "code": "11",
        "name": "Kachin"
      },
      {
        "code": "12",
        "name": "Kayah"
      },
      {
        "code": "13",
        "name": "Kayin"
      },
      {
        "code": "03",
        "name": "Magway"
      },
      {
        "code": "04",
        "name": "Mandalay"
      },
      {
        "code": "15",
        "name": "Mon"
      },
      {
        "code": "16",
        "name": "Rakhine"
      },
      {
        "code": "17",
        "name": "Shan"
      },
      {
        "code": "01",
        "name": "Sagaing"
      },
      {
        "code": "05",
        "name": "Tanintharyi"
      },
      {
        "code": "06",
        "name": "Yangon"
      },
      {
        "code": "18",
        "name": "Naypyidaw Union"
      }
    ]
  },
  {
    "code": "NA",
    "name": "Namibia",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NR",
    "name": "Nauru",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NP",
    "name": "Nepal",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NL",
    "name": "Netherlands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NC",
    "name": "New Caledonia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NZ",
    "name": "New Zealand",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "AUK",
        "name": "Auckland"
      },
      {
        "code": "BOP",
        "name": "Bay of Plenty"
      },
      {
        "code": "CAN",
        "name": "Canterbury"
      },
      {
        "code": "CIT",
        "name": "Chatham Islands Territory"
      },
      {
        "code": "GIS",
        "name": "Gisborne"
      },
      {
        "code": "WGN",
        "name": "Greater Wellington"
      },
      {
        "code": "HKB",
        "name": "Hawke's Bay"
      },
      {
        "code": "MWT",
        "name": "Manawat\u016b-Whanganui"
      },
      {
        "code": "MBH",
        "name": "Marlborough"
      },
      {
        "code": "NSN",
        "name": "Nelson"
      },
      {
        "code": "NTL",
        "name": "Northland"
      },
      {
        "code": "OTA",
        "name": "Otago"
      },
      {
        "code": "STL",
        "name": "Southland"
      },
      {
        "code": "TKI",
        "name": "Taranaki"
      },
      {
        "code": "TAS",
        "name": "Tasman"
      },
      {
        "code": "WKO",
        "name": "Waikato"
      },
      {
        "code": "WTC",
        "name": "West Coast"
      }
    ]
  },
  {
    "code": "NI",
    "name": "Nicaragua",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NE",
    "name": "Niger",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NG",
    "name": "Nigeria",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NU",
    "name": "Niue",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NF",
    "name": "Norfolk Island",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MP",
    "name": "Northern Mariana Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "NO",
    "name": "Norway",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "OM",
    "name": "Oman",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PK",
    "name": "Pakistan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PW",
    "name": "Palau",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PS",
    "name": "Palestinian Territory, Occupied",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PA",
    "name": "Panama",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PG",
    "name": "Papua New Guinea",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PY",
    "name": "Paraguay",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PE",
    "name": "Peru",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PH",
    "name": "Philippines",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "ABR",
        "name": "Abra"
      },
      {
        "code": "AGN",
        "name": "Agusan del Norte"
      },
      {
        "code": "AGS",
        "name": "Agusan del Sur"
      },
      {
        "code": "AKL",
        "name": "Aklan"
      },
      {
        "code": "ALB",
        "name": "Albay"
      },
      {
        "code": "ANT",
        "name": "Antique"
      },
      {
        "code": "APA",
        "name": "Apayao"
      },
      {
        "code": "AUR",
        "name": "Aurora"
      },
      {
        "code": "BAS",
        "name": "Basilan"
      },
      {
        "code": "BAN",
        "name": "Bataan"
      },
      {
        "code": "BTN",
        "name": "Batanes"
      },
      {
        "code": "BTG",
        "name": "Batangas"
      },
      {
        "code": "BEN",
        "name": "Benguet"
      },
      {
        "code": "BIL",
        "name": "Biliran"
      },
      {
        "code": "BOH",
        "name": "Bohol"
      },
      {
        "code": "BUK",
        "name": "Bukidnon"
      },
      {
        "code": "BUL",
        "name": "Bulacan"
      },
      {
        "code": "CAG",
        "name": "Cagayan"
      },
      {
        "code": "CAN",
        "name": "Camarines Norte"
      },
      {
        "code": "CAS",
        "name": "Camarines Sur"
      },
      {
        "code": "CAM",
        "name": "Camiguin"
      },
      {
        "code": "CAP",
        "name": "Capiz"
      },
      {
        "code": "CAT",
        "name": "Catanduanes"
      },
      {
        "code": "CAV",
        "name": "Cavite"
      },
      {
        "code": "CEB",
        "name": "Cebu"
      },
      {
        "code": "NCO",
        "name": "Cotabato"
      },
      {
        "code": "COM",
        "name": "Davao de Oro"
      },
      {
        "code": "DAV",
        "name": "Davao del Norte"
      },
      {
        "code": "DAS",
        "name": "Davao del Sur"
      },
      {
        "code": "DVO",
        "name": "Davao Occidental"
      },
      {
        "code": "DAO",
        "name": "Davao Oriental"
      },
      {
        "code": "DIN",
        "name": "Dinagat Islands"
      },
      {
        "code": "EAS",
        "name": "Eastern Samar"
      },
      {
        "code": "GUI",
        "name": "Guimaras"
      },
      {
        "code": "IFU",
        "name": "Ifugao"
      },
      {
        "code": "ILN",
        "name": "Ilocos Norte"
      },
      {
        "code": "ILS",
        "name": "Ilocos Sur"
      },
      {
        "code": "ILI",
        "name": "Iloilo"
      },
      {
        "code": "ISA",
        "name": "Isabela"
      },
      {
        "code": "KAL",
        "name": "Kalinga"
      },
      {
        "code": "LUN",
        "name": "La Union"
      },
      {
        "code": "LAG",
        "name": "Laguna"
      },
      {
        "code": "LAN",
        "name": "Lanao del Norte"
      },
      {
        "code": "LAS",
        "name": "Lanao del Sur"
      },
      {
        "code": "LEY",
        "name": "Leyte"
      },
      {
        "code": "MDN",
        "name": "Maguindanao del Norte"
      },
      {
        "code": "MDS",
        "name": "Maguindanao del Sur"
      },
      {
        "code": "MAD",
        "name": "Marinduque"
      },
      {
        "code": "MAS",
        "name": "Masbate"
      },
      {
        "code": "MSC",
        "name": "Misamis Occidental"
      },
      {
        "code": "MSR",
        "name": "Misamis Oriental"
      },
      {
        "code": "MOU",
        "name": "Mountain Province"
      },
      {
        "code": "NEC",
        "name": "Negros Occidental"
      },
      {
        "code": "NER",
        "name": "Negros Oriental"
      },
      {
        "code": "NSA",
        "name": "Northern Samar"
      },
      {
        "code": "NUE",
        "name": "Nueva Ecija"
      },
      {
        "code": "NUV",
        "name": "Nueva Vizcaya"
      },
      {
        "code": "MDC",
        "name": "Occidental Mindoro"
      },
      {
        "code": "MDR",
        "name": "Oriental Mindoro"
      },
      {
        "code": "PLW",
        "name": "Palawan"
      },
      {
        "code": "PAM",
        "name": "Pampanga"
      },
      {
        "code": "PAN",
        "name": "Pangasinan"
      },
      {
        "code": "QUE",
        "name": "Quezon"
      },
      {
        "code": "QUI",
        "name": "Quirino"
      },
      {
        "code": "RIZ",
        "name": "Rizal"
      },
      {
        "code": "ROM",
        "name": "Romblon"
      },
      {
        "code": "WSA",
        "name": "Samar"
      },
      {
        "code": "SAR",
        "name": "Sarangani"
      },
      {
        "code": "SIG",
        "name": "Siquijor"
      },
      {
        "code": "SOR",
        "name": "Sorsogon"
      },
      {
        "code": "SCO",
        "name": "South Cotabato"
      },
      {
        "code": "SLE",
        "name": "Southern Leyte"
      },
      {
        "code": "SUK",
        "name": "Sultan Kudarat"
      },
      {
        "code": "SLU",
        "name": "Sulu"
      },
      {
        "code": "SUN",
        "name": "Surigao del Norte"
      },
      {
        "code": "SUR",
        "name": "Surigao del Sur"
      },
      {
        "code": "TAR",
        "name": "Tarlac"
      },
      {
        "code": "TAW",
        "name": "Tawi-Tawi"
      },
      {
        "code": "ZMB",
        "name": "Zambales"
      },
      {
        "code": "ZAN",
        "name": "Zamboanga del Norte"
      },
      {
        "code": "ZAS",
        "name": "Zamboanga del Sur"
      },
      {
        "code": "ZSI",
        "name": "Zamboanga Sibugay"
      },
      {
        "code": "00",
        "name": "Metro Manila"
      }
    ]
  },
  {
    "code": "PN",
    "name": "Pitcairn",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PL",
    "name": "Poland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PT",
    "name": "Portugal",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PR",
    "name": "Puerto Rico",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "QA",
    "name": "Qatar",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "001",
        "name": "Abu Al Dhalouf"
      },
      {
        "code": "002",
        "name": "Abu Hamour"
      },
      {
        "code": "003",
        "name": "Ain Khaled"
      },
      {
        "code": "004",
        "name": "Al Aziziyah"
      },
      {
        "code": "005",
        "name": "Al Bidda"
      },
      {
        "code": "006",
        "name": "Al Dafna"
      },
      {
        "code": "007",
        "name": "Al Dhakira"
      },
      {
        "code": "008",
        "name": "Al Doha Al Jadeeda"
      },
      {
        "code": "009",
        "name": "Al Ebb"
      },
      {
        "code": "010",
        "name": "Al Ghanim"
      },
      {
        "code": "011",
        "name": "Al Hilal"
      },
      {
        "code": "012",
        "name": "Al Hitmi"
      },
      {
        "code": "013",
        "name": "Al Jebailat"
      },
      {
        "code": "014",
        "name": "Al Kharaitiyat"
      },
      {
        "code": "015",
        "name": "Al Khisa"
      },
      {
        "code": "016",
        "name": "Al Khor"
      },
      {
        "code": "017",
        "name": "Al Khor Community"
      },
      {
        "code": "018",
        "name": "Al Khulaifat Al Jadeeda"
      },
      {
        "code": "019",
        "name": "Al Khuwair"
      },
      {
        "code": "020",
        "name": "Al Luqta"
      },
      {
        "code": "021",
        "name": "Al Maamoura"
      },
      {
        "code": "022",
        "name": "Al Mansoura"
      },
      {
        "code": "023",
        "name": "Al Markhiya"
      },
      {
        "code": "024",
        "name": "Al Mashaf"
      },
      {
        "code": "025",
        "name": "Al Messila"
      },
      {
        "code": "026",
        "name": "Al Mirqab"
      },
      {
        "code": "027",
        "name": "Al Jabar"
      },
      {
        "code": "028",
        "name": "Al Muntazah"
      },
      {
        "code": "029",
        "name": "Al Murra"
      },
      {
        "code": "030",
        "name": "Al Najada - Al Souq"
      },
      {
        "code": "031",
        "name": "Al Nasr"
      },
      {
        "code": "032",
        "name": "Al Rayyan Al Jadeed"
      },
      {
        "code": "033",
        "name": "Al Rayyan Al Qadeem"
      },
      {
        "code": "034",
        "name": "Al Rumaila"
      },
      {
        "code": "035",
        "name": "Al Ruwais"
      },
      {
        "code": "036",
        "name": "Al Sadd"
      },
      {
        "code": "037",
        "name": "Al Sailiya"
      },
      {
        "code": "038",
        "name": "Al Sakhama"
      },
      {
        "code": "039",
        "name": "Al Salata"
      },
      {
        "code": "040",
        "name": "Fereej Al Soudan"
      },
      {
        "code": "041",
        "name": "Al Tarfa"
      },
      {
        "code": "042",
        "name": "Al Thumama"
      },
      {
        "code": "043",
        "name": "Al Waab"
      },
      {
        "code": "044",
        "name": "Al Wajba"
      },
      {
        "code": "045",
        "name": "Al Wakrah"
      },
      {
        "code": "046",
        "name": "Al wukair"
      },
      {
        "code": "047",
        "name": "Al Zaeem Area"
      },
      {
        "code": "048",
        "name": "Al-Ghuwayriyah"
      },
      {
        "code": "049",
        "name": "Al-Jumayliyah"
      },
      {
        "code": "050",
        "name": "Bani Hajer"
      },
      {
        "code": "051",
        "name": "Barwa City"
      },
      {
        "code": "052",
        "name": "Barwa Village"
      },
      {
        "code": "053",
        "name": "bu Samra"
      },
      {
        "code": "054",
        "name": "Bu Sidra"
      },
      {
        "code": "055",
        "name": "Dahl Al Hamam"
      },
      {
        "code": "056",
        "name": "Doha Port"
      },
      {
        "code": "057",
        "name": "Duhail"
      },
      {
        "code": "058",
        "name": "Dukhan"
      },
      {
        "code": "059",
        "name": "Education City"
      },
      {
        "code": "060",
        "name": "Fereej Bin Mahmoud"
      },
      {
        "code": "061",
        "name": "Fereej Bin Omran"
      },
      {
        "code": "062",
        "name": "Fereej Kulaib"
      },
      {
        "code": "063",
        "name": "Gharrafat Al Rayyan"
      },
      {
        "code": "064",
        "name": "Hazm Al Markhiya"
      },
      {
        "code": "065",
        "name": "Industrial Area"
      },
      {
        "code": "066",
        "name": "Izghawa"
      },
      {
        "code": "067",
        "name": "Landmark Area"
      },
      {
        "code": "068",
        "name": "Leabaib"
      },
      {
        "code": "069",
        "name": "Luaib"
      },
      {
        "code": "070",
        "name": "Qetaifan Island"
      },
      {
        "code": "071",
        "name": "North Residential"
      },
      {
        "code": "072",
        "name": "Marina"
      },
      {
        "code": "073",
        "name": "Madinat Khalifa"
      },
      {
        "code": "074",
        "name": "Manaseer"
      },
      {
        "code": "075",
        "name": "Medina Centrale"
      },
      {
        "code": "076",
        "name": "Mehairja"
      },
      {
        "code": "077",
        "name": "Mesaieed"
      },
      {
        "code": "078",
        "name": "Mesaimeer"
      },
      {
        "code": "079",
        "name": "Msheireb Downtown"
      },
      {
        "code": "080",
        "name": "Muaither"
      },
      {
        "code": "081",
        "name": "Muraikh"
      },
      {
        "code": "082",
        "name": "Najma"
      },
      {
        "code": "083",
        "name": "New Al Ghanim"
      },
      {
        "code": "084",
        "name": "New Al Hitmi"
      },
      {
        "code": "085",
        "name": "New Salata"
      },
      {
        "code": "086",
        "name": "Nuaija"
      },
      {
        "code": "087",
        "name": "Old Airport"
      },
      {
        "code": "088",
        "name": "Onaiza"
      },
      {
        "code": "089",
        "name": "Porto Arabia"
      },
      {
        "code": "090",
        "name": "Qanat Quartier"
      },
      {
        "code": "091",
        "name": "Qatar Foundation"
      },
      {
        "code": "092",
        "name": "Mall of Qatar"
      },
      {
        "code": "093",
        "name": "Qutaifiya"
      },
      {
        "code": "094",
        "name": "Ras Abu Abboud"
      },
      {
        "code": "095",
        "name": "Rawdat Al Hamama"
      },
      {
        "code": "096",
        "name": "Rawdat Rashid"
      },
      {
        "code": "097",
        "name": "Sumaysimah"
      },
      {
        "code": "098",
        "name": "Umm Al Amad"
      },
      {
        "code": "099",
        "name": "Umm Bab"
      },
      {
        "code": "100",
        "name": "Umm Ghuwailina"
      },
      {
        "code": "101",
        "name": "Umm Lekhba"
      },
      {
        "code": "102",
        "name": "Umm Qarn"
      },
      {
        "code": "103",
        "name": "Umm Salal Ali"
      },
      {
        "code": "104",
        "name": "Umm Salal Muhammed"
      },
      {
        "code": "105",
        "name": "Viva Bahriya"
      },
      {
        "code": "106",
        "name": "West Bay"
      },
      {
        "code": "107",
        "name": "The Pearl-Qatar"
      },
      {
        "code": "108",
        "name": "Lusail"
      }
    ]
  },
  {
    "code": "XK",
    "name": "Republic of Kosovo",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "MK",
    "name": "Republic of North Macedonia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "RE",
    "name": "Reunion",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "RO",
    "name": "Romania",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "AB",
        "name": "Alba"
      },
      {
        "code": "AR",
        "name": "Arad"
      },
      {
        "code": "AG",
        "name": "Arges"
      },
      {
        "code": "BC",
        "name": "Bacau"
      },
      {
        "code": "BH",
        "name": "Bihor"
      },
      {
        "code": "BN",
        "name": "Bistrita-Nasaud"
      },
      {
        "code": "BT",
        "name": "Botosani"
      },
      {
        "code": "BV",
        "name": "Brasov"
      },
      {
        "code": "BR",
        "name": "Braila"
      },
      {
        "code": "B",
        "name": "Bucuresti"
      },
      {
        "code": "BZ",
        "name": "Buzau"
      },
      {
        "code": "CS",
        "name": "Caras-Severin"
      },
      {
        "code": "CL",
        "name": "Calarasi"
      },
      {
        "code": "CJ",
        "name": "Cluj"
      },
      {
        "code": "CT",
        "name": "Constanta"
      },
      {
        "code": "CV",
        "name": "Covasna"
      },
      {
        "code": "DB",
        "name": "Dambovita"
      },
      {
        "code": "DJ",
        "name": "Dolj"
      },
      {
        "code": "GL",
        "name": "Galati"
      },
      {
        "code": "GR",
        "name": "Giurgiu"
      },
      {
        "code": "GJ",
        "name": "Gorj"
      },
      {
        "code": "HR",
        "name": "Harghita"
      },
      {
        "code": "HD",
        "name": "Hunedoara"
      },
      {
        "code": "IL",
        "name": "Ialomita"
      },
      {
        "code": "IS",
        "name": "Iasi"
      },
      {
        "code": "IF",
        "name": "Ilfov"
      },
      {
        "code": "MM",
        "name": "Maramures"
      },
      {
        "code": "MH",
        "name": "Mehedinti"
      },
      {
        "code": "MS",
        "name": "Mures"
      },
      {
        "code": "NT",
        "name": "Neamt"
      },
      {
        "code": "OT",
        "name": "Olt"
      },
      {
        "code": "PH",
        "name": "Prahova"
      },
      {
        "code": "SM",
        "name": "Satu-Mare"
      },
      {
        "code": "SJ",
        "name": "Salaj"
      },
      {
        "code": "SB",
        "name": "Sibiu"
      },
      {
        "code": "SV",
        "name": "Suceava"
      },
      {
        "code": "TR",
        "name": "Teleorman"
      },
      {
        "code": "TM",
        "name": "Timis"
      },
      {
        "code": "TL",
        "name": "Tulcea"
      },
      {
        "code": "VS",
        "name": "Vaslui"
      },
      {
        "code": "VL",
        "name": "Valcea"
      },
      {
        "code": "VN",
        "name": "Vrancea"
      }
    ]
  },
  {
    "code": "RU",
    "name": "Russian Federation",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "RW",
    "name": "Rwanda",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SH",
    "name": "Saint Helena",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "KN",
    "name": "Saint Kitts and Nevis",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "LC",
    "name": "Saint Lucia",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "PM",
    "name": "Saint Pierre and Miquelon",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VC",
    "name": "Saint Vincent and the Grenadines",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "WS",
    "name": "Samoa",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SM",
    "name": "San Marino",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ST",
    "name": "Sao Tome and Principe",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SA",
    "name": "Saudi Arabia",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SN",
    "name": "Senegal",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "RS",
    "name": "Serbia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SC",
    "name": "Seychelles",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SL",
    "name": "Sierra Leone",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SG",
    "name": "Singapore",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SX",
    "name": "Sint Maarten",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SK",
    "name": "Slovakia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SI",
    "name": "Slovenia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SB",
    "name": "Solomon Islands",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SO",
    "name": "Somalia",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ZA",
    "name": "South Africa",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "EC",
        "name": "Eastern Cape"
      },
      {
        "code": "FS",
        "name": "Free State"
      },
      {
        "code": "GT",
        "name": "Gauteng"
      },
      {
        "code": "NL",
        "name": "KwaZulu-Natal"
      },
      {
        "code": "LP",
        "name": "Limpopo"
      },
      {
        "code": "MP",
        "name": "Mpumalanga"
      },
      {
        "code": "NC",
        "name": "Northern Cape"
      },
      {
        "code": "NW",
        "name": "North-West"
      },
      {
        "code": "WC",
        "name": "Western Cape"
      }
    ]
  },
  {
    "code": "GS",
    "name": "South Georgia and the South Sandwich Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ES",
    "name": "Spain",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "C",
        "name": "A Coru\u00f1a"
      },
      {
        "code": "VI",
        "name": "Alava"
      },
      {
        "code": "AB",
        "name": "Albacete"
      },
      {
        "code": "A",
        "name": "Alicante"
      },
      {
        "code": "AL",
        "name": "Almeria"
      },
      {
        "code": "O",
        "name": "Asturias"
      },
      {
        "code": "AV",
        "name": "Avila"
      },
      {
        "code": "BA",
        "name": "Badajoz"
      },
      {
        "code": "PM",
        "name": "Baleares"
      },
      {
        "code": "B",
        "name": "Barcelona"
      },
      {
        "code": "BU",
        "name": "Burgos"
      },
      {
        "code": "CC",
        "name": "Caceres"
      },
      {
        "code": "CA",
        "name": "Cadiz"
      },
      {
        "code": "S",
        "name": "Cantabria"
      },
      {
        "code": "CS",
        "name": "Castellon"
      },
      {
        "code": "CE",
        "name": "Ceuta"
      },
      {
        "code": "CR",
        "name": "Ciudad Real"
      },
      {
        "code": "CO",
        "name": "Cordoba"
      },
      {
        "code": "CU",
        "name": "Cuenca"
      },
      {
        "code": "GI",
        "name": "Girona"
      },
      {
        "code": "GR",
        "name": "Granada"
      },
      {
        "code": "GU",
        "name": "Guadalajara"
      },
      {
        "code": "SS",
        "name": "Guipuzcoa"
      },
      {
        "code": "H",
        "name": "Huelva"
      },
      {
        "code": "HU",
        "name": "Huesca"
      },
      {
        "code": "J",
        "name": "Jaen"
      },
      {
        "code": "LO",
        "name": "La Rioja"
      },
      {
        "code": "GC",
        "name": "Las Palmas"
      },
      {
        "code": "LE",
        "name": "Leon"
      },
      {
        "code": "L",
        "name": "Lleida"
      },
      {
        "code": "LU",
        "name": "Lugo"
      },
      {
        "code": "M",
        "name": "Madrid"
      },
      {
        "code": "MA",
        "name": "Malaga"
      },
      {
        "code": "ML",
        "name": "Melilla"
      },
      {
        "code": "MU",
        "name": "Murcia"
      },
      {
        "code": "NA",
        "name": "Navarra"
      },
      {
        "code": "OR",
        "name": "Ourense"
      },
      {
        "code": "P",
        "name": "Palencia"
      },
      {
        "code": "PO",
        "name": "Pontevedra"
      },
      {
        "code": "SA",
        "name": "Salamanca"
      },
      {
        "code": "TF",
        "name": "Santa Cruz de Tenerife"
      },
      {
        "code": "SG",
        "name": "Segovia"
      },
      {
        "code": "SE",
        "name": "Sevilla"
      },
      {
        "code": "SO",
        "name": "Soria"
      },
      {
        "code": "T",
        "name": "Tarragona"
      },
      {
        "code": "TE",
        "name": "Teruel"
      },
      {
        "code": "TO",
        "name": "Toledo"
      },
      {
        "code": "V",
        "name": "Valencia"
      },
      {
        "code": "VA",
        "name": "Valladolid"
      },
      {
        "code": "BI",
        "name": "Vizcaya"
      },
      {
        "code": "ZA",
        "name": "Zamora"
      },
      {
        "code": "Z",
        "name": "Zaragoza"
      }
    ]
  },
  {
    "code": "LK",
    "name": "Sri Lanka",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SD",
    "name": "Sudan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SR",
    "name": "Suriname",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SJ",
    "name": "Svalbard and Jan Mayen",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SZ",
    "name": "Swaziland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "SE",
    "name": "Sweden",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "CH",
    "name": "Switzerland",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "AG",
        "name": "Aargau"
      },
      {
        "code": "AI",
        "name": "Appenzell Innerrhoden"
      },
      {
        "code": "AR",
        "name": "Appenzell Ausserrhoden"
      },
      {
        "code": "BE",
        "name": "Bern"
      },
      {
        "code": "BL",
        "name": "Basel-Landschaft"
      },
      {
        "code": "BS",
        "name": "Basel-Stadt"
      },
      {
        "code": "FR",
        "name": "Fribourg"
      },
      {
        "code": "GE",
        "name": "Geneva"
      },
      {
        "code": "GL",
        "name": "Glarus"
      },
      {
        "code": "GR",
        "name": "Graub\u00fcnden"
      },
      {
        "code": "JU",
        "name": "Jura"
      },
      {
        "code": "LU",
        "name": "Luzern"
      },
      {
        "code": "NE",
        "name": "Neuch\u00e2tel"
      },
      {
        "code": "NW",
        "name": "Nidwalden"
      },
      {
        "code": "OW",
        "name": "Obwalden"
      },
      {
        "code": "SG",
        "name": "St. Gallen"
      },
      {
        "code": "SH",
        "name": "Schaffhausen"
      },
      {
        "code": "SO",
        "name": "Solothurn"
      },
      {
        "code": "SZ",
        "name": "Schwyz"
      },
      {
        "code": "TG",
        "name": "Thurgau"
      },
      {
        "code": "TI",
        "name": "Ticino"
      },
      {
        "code": "UR",
        "name": "Uri"
      },
      {
        "code": "VD",
        "name": "Vaud"
      },
      {
        "code": "VS",
        "name": "Valais"
      },
      {
        "code": "ZG",
        "name": "Zug"
      },
      {
        "code": "ZH",
        "name": "Z\u00fcrich"
      }
    ]
  },
  {
    "code": "TW",
    "name": "Taiwan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TJ",
    "name": "Tajikistan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TZ",
    "name": "Tanzania, United Republic of",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TH",
    "name": "Thailand",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TL",
    "name": "Timor-Leste",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TG",
    "name": "Togo",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TK",
    "name": "Tokelau",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TO",
    "name": "Tonga",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TT",
    "name": "Trinidad and Tobago",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TN",
    "name": "Tunisia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TR",
    "name": "Turkey",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TM",
    "name": "Turkmenistan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TC",
    "name": "Turks and Caicos Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "TV",
    "name": "Tuvalu",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "UG",
    "name": "Uganda",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "UA",
    "name": "Ukraine",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "AE",
    "name": "United Arab Emirates",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": [
      {
        "code": "AZ",
        "name": "Abu Dhabi"
      },
      {
        "code": "AJ",
        "name": "Ajman"
      },
      {
        "code": "FU",
        "name": "Fujairah"
      },
      {
        "code": "SH",
        "name": "Sharjah"
      },
      {
        "code": "DU",
        "name": "Dubai"
      },
      {
        "code": "RK",
        "name": "Ras al-Khaimah"
      },
      {
        "code": "UQ",
        "name": "Umm al-Quwain"
      }
    ]
  },
  {
    "code": "GB",
    "name": "United Kingdom",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": [],
    "suburbLabel": "Town/City",
    "stateLabel": "County"
  },
  {
    "code": "US",
    "name": "United States",
    "hasPostalCodes": true,
    "requiresState": true,
    "subdivisions": [
      {
        "code": "AL",
        "name": "Alabama"
      },
      {
        "code": "AK",
        "name": "Alaska"
      },
      {
        "code": "AS",
        "name": "American Samoa"
      },
      {
        "code": "AZ",
        "name": "Arizona"
      },
      {
        "code": "AR",
        "name": "Arkansas"
      },
      {
        "code": "AA",
        "name": "Armed Forces Americas"
      },
      {
        "code": "AE",
        "name": "Armed Forces Europe"
      },
      {
        "code": "AP",
        "name": "Armed Forces Pacific"
      },
      {
        "code": "CA",
        "name": "California"
      },
      {
        "code": "CO",
        "name": "Colorado"
      },
      {
        "code": "CT",
        "name": "Connecticut"
      },
      {
        "code": "DE",
        "name": "Delaware"
      },
      {
        "code": "DC",
        "name": "District of Columbia"
      },
      {
        "code": "FM",
        "name": "Federated States Of Micronesia"
      },
      {
        "code": "FL",
        "name": "Florida"
      },
      {
        "code": "GA",
        "name": "Georgia"
      },
      {
        "code": "GU",
        "name": "Guam"
      },
      {
        "code": "HI",
        "name": "Hawaii"
      },
      {
        "code": "ID",
        "name": "Idaho"
      },
      {
        "code": "IL",
        "name": "Illinois"
      },
      {
        "code": "IN",
        "name": "Indiana"
      },
      {
        "code": "IA",
        "name": "Iowa"
      },
      {
        "code": "KS",
        "name": "Kansas"
      },
      {
        "code": "KY",
        "name": "Kentucky"
      },
      {
        "code": "LA",
        "name": "Louisiana"
      },
      {
        "code": "ME",
        "name": "Maine"
      },
      {
        "code": "MH",
        "name": "Marshall Islands"
      },
      {
        "code": "MD",
        "name": "Maryland"
      },
      {
        "code": "MA",
        "name": "Massachusetts"
      },
      {
        "code": "MI",
        "name": "Michigan"
      },
      {
        "code": "MN",
        "name": "Minnesota"
      },
      {
        "code": "MS",
        "name": "Mississippi"
      },
      {
        "code": "MO",
        "name": "Missouri"
      },
      {
        "code": "MT",
        "name": "Montana"
      },
      {
        "code": "NE",
        "name": "Nebraska"
      },
      {
        "code": "NV",
        "name": "Nevada"
      },
      {
        "code": "NH",
        "name": "New Hampshire"
      },
      {
        "code": "NJ",
        "name": "New Jersey"
      },
      {
        "code": "NM",
        "name": "New Mexico"
      },
      {
        "code": "NY",
        "name": "New York"
      },
      {
        "code": "NC",
        "name": "North Carolina"
      },
      {
        "code": "ND",
        "name": "North Dakota"
      },
      {
        "code": "MP",
        "name": "Northern Mariana Islands"
      },
      {
        "code": "OH",
        "name": "Ohio"
      },
      {
        "code": "OK",
        "name": "Oklahoma"
      },
      {
        "code": "OR",
        "name": "Oregon"
      },
      {
        "code": "PW",
        "name": "Palau"
      },
      {
        "code": "PA",
        "name": "Pennsylvania"
      },
      {
        "code": "PR",
        "name": "Puerto Rico"
      },
      {
        "code": "RI",
        "name": "Rhode Island"
      },
      {
        "code": "SC",
        "name": "South Carolina"
      },
      {
        "code": "SD",
        "name": "South Dakota"
      },
      {
        "code": "TN",
        "name": "Tennessee"
      },
      {
        "code": "TX",
        "name": "Texas"
      },
      {
        "code": "UT",
        "name": "Utah"
      },
      {
        "code": "VT",
        "name": "Vermont"
      },
      {
        "code": "VI",
        "name": "Virgin Islands"
      },
      {
        "code": "VA",
        "name": "Virginia"
      },
      {
        "code": "WA",
        "name": "Washington"
      },
      {
        "code": "WV",
        "name": "West Virginia"
      },
      {
        "code": "WI",
        "name": "Wisconsin"
      },
      {
        "code": "WY",
        "name": "Wyoming"
      }
    ]
  },
  {
    "code": "UM",
    "name": "United States Minor Outlying Islands",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "UY",
    "name": "Uruguay",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "UZ",
    "name": "Uzbekistan",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VU",
    "name": "Vanuatu",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VE",
    "name": "Venezuela",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VN",
    "name": "Viet Nam",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VG",
    "name": "Virgin Islands, British",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "VI",
    "name": "Virgin Islands, U.S.",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "WF",
    "name": "Wallis and Futuna",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "EH",
    "name": "Western Sahara",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "YE",
    "name": "Yemen",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ZM",
    "name": "Zambia",
    "hasPostalCodes": true,
    "requiresState": false,
    "subdivisions": []
  },
  {
    "code": "ZW",
    "name": "Zimbabwe",
    "hasPostalCodes": false,
    "requiresState": false,
    "subdivisions": []
  }
]
