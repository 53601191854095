import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import CookieConsent from "react-cookie-consent";
import { svgPath } from 'utils/socialIcons';
import { FormattedMessage } from "react-intl";

const footerStyle = {color: window.FOOTER_FONT_COLOUR};

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCookiePreference: false
    };

    this.cookieConsentRef = React.createRef();
  }

  render() {
    const { site } = this.props;

    return (
      <React.Fragment>
        {site.footer_template == 'simple' &&
          <div className="simple-footer">
            {site.custom_page_groups.map((group) =>
              group.custom_pages.map((custom_page, index) =>
                <div key={index} className='custom-page-nav'>
                  {custom_page.content_external && custom_page.content_url ?
                    <a href={custom_page.content_url} target='_blank' className='button-link'><Button style={footerStyle}>{custom_page.name}</Button></a>
                  :
                    <NavLink exact to={'/' + custom_page.path} className='button-link'><Button style={footerStyle}>{custom_page.name}</Button></NavLink>
                  }
                </div>
              )
            )}
            <Typography align="center" style={footerStyle}>
              <FormattedMessage id="footer.powered_by" defaultMessage="Powered by"/>
              {` ${site.powered_by}`}
            </Typography>
          </div>
        }
        {site.footer_template == 'advanced' &&
          <footer id="footer" style={{backgroundColor: window.FOOTER_BACKGROUND_COLOUR}}>
            <div className="top-footer grid-outer">
              <div className="footer-inner grid">
                {site.custom_page_groups.map((group, index) =>
                  <nav key={index} className="footer-nav">
                    <ul className="footer-menu">
                      <li>
                        <Typography className="footer-menu-header footer-menu-item" style={{color: window.FOOTER_FONT_COLOUR}}>{group.label}</Typography>
                        <ul>
                          {group.custom_pages.map((custom_page, pageIndex) =>
                            <li key={pageIndex}>
                              {custom_page.content_external && custom_page.content_url ?
                                <a href={custom_page.content_url} target='_blank' className='button-link'>
                                  <Typography className="footer-menu-item" style={{color: window.FOOTER_FONT_COLOUR}}>{custom_page.name}</Typography>
                                </a>
                              :
                                custom_page.template == 'cookie_preference' ?
                                  document.getElementById('cookieyes') ?
                                    <a href="#" className='button-link cky-banner-element'>
                                      <Typography className="footer-menu-item" style={{color: window.FOOTER_FONT_COLOUR}}>{custom_page.name}</Typography>
                                    </a>
                                  :
                                    <React.Fragment>
                                      <CookieConsent
                                        ref={this.cookieConsentRef}
                                        visible={this.state.showCookiePreference ? 'show' : 'byCookieValue'}
                                        onAccept={() => this.setState({showCookiePreference: false})}
                                        style={{background: 'white', border: '1px solid grey'}}
                                        ButtonComponent={() => <Button variant='contained' color='primary' className="cookie-banner-accept" onClick={() => this.cookieConsentRef.current.accept()}>
                                                                 <FormattedMessage id="cookies.accept_required" defaultMessage="Accept required cookies"/>
                                                               </Button>}
                                      >
                                        <Typography variant="h6" paragraph>
                                          <FormattedMessage id="cookies.required_cookies" defaultMessage="Required Cookies"/>
                                        </Typography>
                                        <Typography>
                                          <FormattedMessage id="cookies.declaration" />
                                        </Typography>
                                      </CookieConsent>
                                      <a href="javascript:void(0)" className='button-link' onClick={() => this.setState({showCookiePreference: true})}>
                                        <Typography className="footer-menu-item" style={{color: window.FOOTER_FONT_COLOUR}}>{custom_page.name}</Typography>
                                      </a>
                                    </React.Fragment>
                                :
                                  <NavLink exact to={'/' + custom_page.path} className='button-link'>
                                    <Typography className="footer-menu-item" style={{color: window.FOOTER_FONT_COLOUR}}>{custom_page.name}</Typography>
                                  </NavLink>
                              }
                            </li>
                          )}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                )}

                <div className="footer-right">
                  {site.social_links.length > 0 &&
                    <div className="social-icons">
                      {site.social_links.map((link, index) =>
                        <a key={index} href={link.url} title={`Find us on ${link.type}`} target="_blank">
                          <svg>
                            <path d={svgPath(link.type)} style={{fill: window.FOOTER_FONT_COLOUR}} />
                            <title>{link.type}</title>
                          </svg>
                        </a>
                      )}
                    </div>
                  }

                  {site.footer_image &&
                    <div className="footer-image">
                      <img src={site.footer_image} alt='Footer Image' />
                    </div>
                  }
                </div>

                {site.parent_url && site.parent_url_text &&
                  <div className="footer-link">
                    <a href={site.parent_url} target='_blank' style={{textDecorationColor: window.FOOTER_FONT_COLOUR}}>
                      <Typography style={{color: window.FOOTER_FONT_COLOUR}}>{site.parent_url_text}</Typography>
                    </a>
                  </div>
                }
              </div>
            </div>

            <div className="bottom-footer grid-outer" style={{backgroundColor: window.ATTRIBUTION_BACKGROUND_COLOUR}}>
              <div className="footer-inner grid">
                <span />
                {site.powered_by &&
                  <Typography className="attribution" style={{color: window.ATTRIBUTION_FONT_COLOUR}}>
                    <FormattedMessage id="footer.powered_by" defaultMessage="Powered by"/>
                    {` ${site.powered_by}`}
                  </Typography>
                }
              </div>
            </div>
          </footer>
        }
      </React.Fragment>
    )
  }
}
