import {
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  SUBMIT_ORDER_REQUEST,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
  VERIFY_ORDER_REQUEST,
  VERIFY_ORDER_SUCCESS,
  VERIFY_ORDER_FAILURE
} from 'actions/OrderActions';

const initialState = {
  isFetching: false,
  isProcessing: false,
  order_reference_number: null,
  order: null,
  payment_config: null
}

export default function order(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_ORDER_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        order: action.order
      });
    }
    case FETCH_ORDER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        order: null
      });
    }
    case SUBMIT_ORDER_REQUEST:
    case VERIFY_ORDER_REQUEST: {
      return Object.assign({}, state, {
        payment_config: null,
        isProcessing: true
      });
    }
    case SUBMIT_ORDER_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        order_reference_number: action.order_reference_number,
        payment_config: action.payment_config
      });
    }
    case SUBMIT_ORDER_FAILURE:
    case VERIFY_ORDER_SUCCESS:
    case VERIFY_ORDER_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    default:
      return state;
  }
}
