import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { appendSeoBrand } from 'utils/seo'
import { FormattedMessage } from 'react-intl';
import { fetchCollectionGroups } from 'actions/CollectionActions';

export class SitemapPage extends React.Component {
  componentDidMount() {
    this.props.fetchCollectionGroups(window.SITE_ID, this.props.locale);
  }

  render() {
    const { site, collectionGroups, name, page_title, description, block_search_engines } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>{appendSeoBrand(page_title)}</title>
          <meta name="description" content={description} />
          <meta name="robots" content={window.APP_ENV === 'production' && !block_search_engines ? 'index' : 'noindex'} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Typography variant="h4" component="h1" gutterBottom align="center">{name}</Typography>

        <Typography variant="h5" component="h1" gutterBottom className="top-space">
          <FormattedMessage id="sitemap.pages" defaultMessage="Pages" />
        </Typography>
        <NavLink exact to="/">
          <Typography variant="h6"><FormattedMessage id="sitemap.home" defaultMessage="Home" /></Typography>
        </NavLink>
        {!site.disable_balance_check &&
          <NavLink exact to="/balance">
            <Typography variant="h6"><FormattedMessage id="navigation.check_balance" defaultMessage="Check Balance" /></Typography>
          </NavLink>
        }
        {site.custom_page_groups.map((group) =>
          group.custom_pages.map((custom_page, index) =>
            <NavLink key={index} exact to={'/' + custom_page.path}>
              <Typography variant="h6">{custom_page.name}</Typography>
            </NavLink>
          )
        )}

        {collectionGroups.length > 0 &&
          <React.Fragment>
            <Typography variant="h5" component="h1" gutterBottom className="top-space">
              <FormattedMessage id="sitemap.categories" defaultMessage="Categories" />
            </Typography>
            {collectionGroups.map((group, index) =>
              <React.Fragment key={index}>
                <NavLink exact to={'/categories/' + group.slug}>
                  <Typography variant="h6">{group.label}</Typography>
                </NavLink>
                {group.collections.map((collection, index) =>
                  <div key={index}>
                    <React.Fragment><Typography variant="h6" style={{display: 'inline'}}>- </Typography>
                      <NavLink exact to={'/collections/' + collection.slug}>
                        <Typography variant="h6" style={{display: 'inline'}}>{collection.name}</Typography>
                      </NavLink>
                    </React.Fragment>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        }

      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    site: state.site.site,
    collectionGroups: state.collection.collectionGroups
  };
}

const mapDispatchToProps = {
  fetchCollectionGroups: (siteId, locale) => fetchCollectionGroups(siteId, locale)
}

export default connect(mapStateToProps, mapDispatchToProps)(SitemapPage);
