// format australian mobile number
export function formatMobileNumber(number) {
  switch (window.LOCALE.slice(-2)) {
    case 'AU':
      return number.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
    case 'GB':
      return number.replace(/(\d{5})(\d)/, '$1 $2');
    default:
      return number;
  }
}
