import React from 'react';
import Formsy from 'formsy-react';
import _ from 'lodash';
import { Grid, Typography, Button } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import { localeGlobal, minMobileNumberLength, maxMobileNumberLength, mobileNumberLengthRange } from 'utils/locales';
import { getCountries, getCountry } from 'utils/countries';
import { FormattedMessage, injectIntl } from "react-intl";

export class DetailsStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      billingSameAddress: this.props.formData.billing_same_address || '1',
      country: getCountry(window.LOCALE)
    };

    this.requiredValidation = { isDefaultRequiredValue: props.intl.formatMessage({id: "validation_required", defaultMessage: "can't be blank"})};
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.formRef.current.reset(this.props.formData);
  }

  renderAddressFields(prefix) {
    const { country } = this.state;
    const { intl } = this.props;

    return (
      <div style={{marginBottom: '2em'}}>
        <TextInput name={prefix + '_first_name'}
                   label={intl.formatMessage({id: "checkout.details.first_name", defaultMessage: "First Name"})}
                   required
          validationErrors={this.requiredValidation}
        />
        <TextInput name={prefix + '_last_name'}
                   label={intl.formatMessage({id: "checkout.details.last_name", defaultMessage: "Last Name"})}
                   required
          validationErrors={this.requiredValidation}
        />
        <TextInput name={prefix + '_company_name'}
                   label={intl.formatMessage({id: "checkout.details.company", defaultMessage: "Company Name (Optional)"})}
        />
        <TextInput name={prefix + '_street_address'}
                   label={intl.formatMessage({id: "checkout.details.street", defaultMessage: "Street Address"})}
                   required
          validationErrors={this.requiredValidation}
        />
        <SelectInput name={prefix + '_country'}
                     label={intl.formatMessage({id: "checkout.details.country", defaultMessage: "Country"})}
                     required
          options={getCountries()}
          value={country.code}
          disabled={!localeGlobal()}
          onChange={(e) => this.setState({country: getCountry(e.target.value)})}
          validationErrors={this.requiredValidation}
        />
        <TextInput name={prefix + '_suburb'}
                   label={country.suburbLabel || intl.formatMessage({id: "checkout.details.suburb", defaultMessage: "Suburb"})}
                   required
          validationErrors={this.requiredValidation}
        />
        {country && country.subdivisions.length > 0 ?
          <SelectInput name={prefix + '_state'}
                       label={country.stateLabel || intl.formatMessage({id: "checkout.details.state", defaultMessage: "State"})}
                       required={country && country.requiresState}
            options={country ? country.subdivisions.map(s => ({value: s["code"], label: s["name"]})) : []}
            validationErrors={this.requiredValidation}
          />
        :
          <TextInput name={prefix + '_state'}
                     label={country.stateLabel || intl.formatMessage({id: "checkout.details.province", defaultMessage: "State/Province"})}
          />
        }
        <TextInput type={window.LOCALE == 'AU' ? 'number' : null}
                   name={prefix + '_postcode'}
                   label={intl.formatMessage({id: "checkout.details.postcode", defaultMessage: "Postcode"})}
                   required={country && country.hasPostalCodes}
          validations={window.LOCALE == 'AU' ? 'isLength:4' : null}
          validationErrors={_.merge(this.requiredValidation, {
            isLength: intl.formatMessage({id: "checkout.details.postcode_aus_invalid", defaultMessage: "must be 4 digits"})
          })}
        />

        <Typography color="textSecondary" style={{marginTop: '1em'}}>
          <FormattedMessage id="checkout.details.int_address_message" defaultMessage="International addresses will not be accepted."/>
        </Typography>
      </div>
    );
  }

  render() {
    const { handleNext, handleBack, site, digitalOnly, mobileRequired, intl } = this.props;
    const { billingSameAddress } = this.state;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleNext} className='full-width' ref={this.formRef}>
          <Grid container spacing={16} justify="center">
            <Grid item xs={12} md={6}>
              <TextInput name='email'
                         label={intl.formatMessage({id: "checkout.details.email", defaultMessage: "Email"})}
                         required
                hint={digitalOnly ?
                        intl.formatMessage({id: "checkout.details.email_hint_digital", defaultMessage: "For invoicing and payment confirmation (This is NOT where digital cards are delivered to)"})
                        :
                        intl.formatMessage({id: "checkout.details.email_hint", defaultMessage: "For invoice and tracking information. (This is NOT where digital cards are delivered to)"})
                }
                validations='isEmail'
                validationErrors={_.merge(this.requiredValidation, {
                  isEmail: intl.formatMessage({id: "checkout.details.email_invalid", defaultMessage: "Invalid email"})
                })}
              />

              <TextInput name='email_confirmation'
                         label={intl.formatMessage({id: "checkout.details.email_confirmation", defaultMessage: "Email Confirmation"})}
                         required
                validations='isEmail,equalsField:email'
                validationErrors={_.merge(this.requiredValidation, {
                  isEmail: intl.formatMessage({id: "checkout.details.email_invalid", defaultMessage: "Invalid email"}),
                  equalsField: intl.formatMessage({id: "checkout.details.email_mismatch", defaultMessage: "Must match email"})
                })}
              />

              {(window.LOCALE == 'AU' || mobileRequired) &&
                <React.Fragment>
                  <TextInput name='phone_number'
                             label={intl.formatMessage({id: "checkout.details.phone", defaultMessage: "Mobile Number"})}
                             required
                             validations={{minLength: minMobileNumberLength(), maxLength: maxMobileNumberLength()}}
                             validationError={intl.formatMessage({id: "order.delivery_sms_length", defaultMessage: "must be {length} digits"}, { length: mobileNumberLengthRange() })}
                             validationErrors={this.requiredValidation}
                  />

                  {mobileRequired &&
                    <TextInput type='number' name='phone_number_confirmation'
                               label={intl.formatMessage({id: "checkout.details.phone_confirmation", defaultMessage: "Mobile Number Confirmation"})}
                               required
                               validations={{minLength: minMobileNumberLength(), maxLength: maxMobileNumberLength(), equalsField: `phone_number`}}
                               validationError={intl.formatMessage({id: "order.delivery_sms_length", defaultMessage: "must be {length} digits"}, { length: mobileNumberLengthRange() })}
                               validationErrors={_.merge(this.requiredValidation, {equalsField: intl.formatMessage({id: "order.delivery_sms_mismatch", defaultMessage: "must match recipient number"})})}
                    />
                  }
                </React.Fragment>
              }

              {!digitalOnly &&
                <React.Fragment>
                  <Typography variant='h5' align='center' style={{marginTop: '2em'}}>
                    <FormattedMessage id="checkout.details.recipient" defaultMessage="Recipient Details"/>
                  </Typography>

                  {this.renderAddressFields('recipient')}

                  <CheckboxInput name="billing_same_address"
                                 label={intl.formatMessage({id: "checkout.details.use_for_billing_address", defaultMessage: "Use same address for billing"})}
                    value={billingSameAddress}
                    onChange={(v) => this.setState({billingSameAddress: v})}
                  />
                </React.Fragment>
              }

              {(billingSameAddress === '0' || digitalOnly) &&
                <React.Fragment>
                  <Typography variant='h5' align='center' style={{marginTop: '1em'}}>
                    <FormattedMessage id="checkout.details.billing" defaultMessage="Billing Details"/>
                  </Typography>

                  {this.renderAddressFields('billing')}
                </React.Fragment>
              }

              {site.include_source_collection &&
                <SelectInput name="source"
                             label={intl.formatMessage({id: "checkout.details.source", defaultMessage: "How did you hear about our gift card?"})}
                             options={site.source_options}
                />
              }
              {site.include_marketing_collection &&
                <CheckboxInput name="marketing_consent"
                               label={intl.formatMessage({id: "checkout.details.marketing_consent", defaultMessage: "I agree to receive marketing emails"})}
                />
              }
            </Grid>
          </Grid>

          <div className='button-container'>
            {site.closed_loop_checkout_step &&
              <Button variant='contained' onClick={handleBack}>
                <FormattedMessage id="checkout.confirm.back" defaultMessage="Back"/>
              </Button>
            }
            <Button type='submit' variant='contained' color='primary'>
              <FormattedMessage id="checkout.details.proceed" defaultMessage="Next"/>
            </Button>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

export default injectIntl(DetailsStep);
