import { pageView } from 'utils/tracking';
import {
  FETCH_SITE_REQUEST,
  FETCH_SITE_SUCCESS,
  FETCH_SITE_FAILURE
} from 'actions/SiteActions';

const initialState = {
  isFetching: false,
  site: null,
  config: {}
};

export default function site(state = initialState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      pageView(action.payload.location.pathname);
      return state;
    }
    case FETCH_SITE_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
//      site: null - Commented out to test improving the speed of switching languages
      });
    }
    case FETCH_SITE_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        site: action.site,
        config: action.config
      });
    }
    case FETCH_SITE_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
}
