import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import Notice from 'components/Notice';
import renderHTML from 'react-render-html';
import _ from 'lodash';
import CardGallery from 'components/CardGallery';
import { FormattedMessage } from "react-intl";

export class LandingPage extends React.Component {
  galleryItems() {
    const { products, filter } = this.props;

    return _.filter(products, (c) => {
      if (filter) {
        // Replicate existing behaviour i.e. digital shows both digital and digital retailer
        // If we want to split digital might need a different panel in LandingPage for digital retailer
        return c.product_type.includes(filter) && c.active;
      } else {
        return c.active;
      }
    })
  }

  render() {
    const { site, config, filter } = this.props;

    return (
      <React.Fragment>
        {config.notice_enabled &&
          <Notice text={renderHTML(config.notice)} />
        }
        {site.landing_text &&
          <Typography component="div" align="center" style={{marginBottom: '3em'}}>{renderHTML(site.landing_text)}</Typography>
        }
        {(site.landing_product_selection && !filter) ?
          <Grid container spacing={8} justify="center" align='center'>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" align="center" gutterBottom>
                <FormattedMessage id="landing.physical_cards" defaultMessage="Physical Gift Cards"/>
              </Typography>
              <img src={site.landing_physical_image} className="landing-product-tile" alt='Physical' />
              <Typography component="div" align="center" gutterBottom>{renderHTML(site.landing_physical_features)}</Typography>
              <NavLink to="/physical" className='button-link'>
                <Button variant='contained' color='primary'>
                  <FormattedMessage id="landing.physical_proceed" defaultMessage="Buy Physical Gift Cards"/>
                </Button>
              </NavLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" align="center" gutterBottom>
                <FormattedMessage id="landing.digital_cards" defaultMessage="Digital Gift Cards"/>
              </Typography>
              <img src={site.landing_digital_image} className="landing-product-tile" alt='Digital' />
              <Typography component="div" align="center" gutterBottom>{renderHTML(site.landing_digital_features)}</Typography>
              <NavLink to="/digital" className='button-link'>
                <Button variant='contained' color='primary'>
                  <FormattedMessage id="landing.digital_proceed" defaultMessage="Buy Digital Gift Cards"/>
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        :
          <CardGallery site={site} cardDesigns={this.galleryItems()} filter={filter} />
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    products: state.product.products,
    config: state.site.config
  };
}

export default connect(mapStateToProps, {})(LandingPage);
