import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Typography, Icon } from '@material-ui/core';

export default class Footer extends React.Component {
  render() {
    const { features } = this.props;

    return (
      features.length > 0 &&
        <section className="info-bar-container grid-outer">
          <article className="info-bar small-text grid">
            <OwlCarousel className='owl-theme' dots={false} autoplay autoplayTimeout={2000} autoWidth responsive={{
              0: {
                loop: true,
                center: true,
                items: 1,
                autoWidth: false
              },
              767: {
                loop: false,
                center: false,
                items: 0,
                autoWidth: true
              }
            }}>
              {features.map((feature, index) =>
                <div className="item" key={index}>
                  <Typography style={{fontWeight: 'bold'}}>
                    <Icon className="outlined" style={{color: '#' + feature.icon_colour}}>{feature.icon}</Icon>
                    {feature.title}
                  </Typography>
                </div>
              )}
            </OwlCarousel>
          </article>
        </section>
    )
  }
}
