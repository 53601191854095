import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Typography, Grid, Button, CircularProgress, InputAdornment } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchCollectionGroup } from 'actions/CollectionActions';
import { appendSeoBrand } from 'utils/seo'
import Formsy from "formsy-react";
import TextInput from 'components/inputs/TextInput';
import SearchIcon from "@material-ui/icons/Search";
import * as qs from 'query-string';

export class CollectionGroupPage extends React.Component {

  constructor(props) {
    super(props);

    const params = qs.parse(location.search);

    this.state = {
      search: params['search'] || ''
    };
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  componentDidMount() {
    this.props.fetchCollectionGroup(window.SITE_ID, this.props.match.params.categoryName, this.props.locale);
  }

  // To handle case where navigating from one collection group to another, which doesn't remount but changes the ID in the URL
  // Also handles when locale is changed and new translations need to be pulled
  componentDidUpdate(prevProps) {
    if (this.props.match.params.categoryName != prevProps.match.params.categoryName || this.props.locale !== prevProps.locale) {
      this.props.fetchCollectionGroup(window.SITE_ID, this.props.match.params.categoryName, this.props.locale);
    }
  }

  handleChangeSearch(data) {
    this.setState({search: data.collection_search})
  }

  render() {
    const { collectionGroup, isFetching, intl } = this.props;

    var params = {
      search: this.state.search
    }
    window.history.replaceState(null, null, `${location.pathname}?${qs.stringify(params)}`);

    if (collectionGroup) {
      var collections = collectionGroup.collections;
      if (this.state.search) {
        var collections = _.filter(collectionGroup.collections, (c) => { return c.name.toLowerCase().includes(this.state.search.toLowerCase()) });
      }
    }

    return (
      <React.Fragment>
        {isFetching &&
          <center><CircularProgress color="inherit" size={50} /></center>
        }
        {collectionGroup &&
          <React.Fragment>
            <Helmet>
              <title>{appendSeoBrand(collectionGroup.page_title)}</title>
              <meta name="description" content={collectionGroup.description} />
              <meta name="robots" content={window.APP_ENV === 'production' ? 'index' : 'noindex'} />
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Typography variant='h4' component="h1" align='center' className="bottom-space">{collectionGroup.label}</Typography>

            <Grid container spacing={8} justify={collectionGroup.collections.length === 1 ? 'center' : 'flex-start'} align='center'>
              {collectionGroup.collections.length > 8 &&
                <Grid item xs={12} align="left">
                  <Formsy onChange={this.handleChangeSearch}>
                    <TextInput type="search" name="collection_search" value={this.state.search} label={intl.formatMessage({id: "order.search", defaultMessage: "Search"})} inlineLabel
                               inputProps={{
                                 endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>
                               }}
                    />
                  </Formsy>
                </Grid>
              }
              {collections.map((collection, index) =>
                <Grid item key={index} xs={12} md={3}>
                  <div className='gallery-item-grid'>
                    <NavLink to={'/collections/' + collection.slug} className='button-link'>
                      {collection.image && <img src={collection.image} alt={collection.name} />}
                    </NavLink>
                    <Typography variant="h6" align="left" gutterBottom>{collection.name}</Typography>

                    <NavLink to={'/collections/' + collection.slug} className='button-link' style={{textAlign: 'start', marginTop: '2em'}}>
                      <Button variant='contained' size='medium' color='primary'>
                        <FormattedMessage id="collection.view" defaultMessage="View" />
                      </Button>
                    </NavLink>
                  </div>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale.locale,
    isFetching: state.collection.isFetching,
    collectionGroup: state.collection.collectionGroup
  };
}

const mapDispatchToProps = {
  fetchCollectionGroup: (siteId, name, locale) => fetchCollectionGroup(siteId, name, locale)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CollectionGroupPage)));
