import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel } from '@material-ui/core';
import MediaQuery from 'react-responsive';
import {injectIntl} from "react-intl";

const styles = theme => ({
  root: {
    width: '90%'
  }
});

export class ResponsiveStepper extends React.Component {
  render() {
    const { steps, activeStep, classes, intl } = this.props;

    return (
      <React.Fragment>
        <MediaQuery maxWidth={767}>
          <Stepper activeStep={activeStep} orientation='vertical' style={{backgroundColor: window.BACKGROUND_COLOUR}}>
            {steps.map(label => (
              <Step key={label.key} className="checkout-step">
                <StepLabel>
                  {intl.formatMessage({id: label.key, defaultMessage: label.title_en})}
                </StepLabel>
            </Step>
            ))}
          </Stepper>
        </MediaQuery>

        <MediaQuery minWidth={767}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} style={{backgroundColor: window.BACKGROUND_COLOUR}}>
              {steps.map(label => (
                <Step key={label.key} className="checkout-step">
                  <StepLabel>
                    {intl.formatMessage({id: label.key, defaultMessage: label.title_en})}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(ResponsiveStepper));
