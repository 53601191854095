import axios from 'axios';

export const FETCH_CATALOGUE_REQUEST = 'FETCH_CATALOGUE_REQUEST';
export const FETCH_CATALOGUE_SUCCESS = 'FETCH_CATALOGUE_SUCCESS';
export const FETCH_CATALOGUE_FAILURE = 'FETCH_CATALOGUE_FAILURE';

function fetchCatalogueRequest() {
  return {
    type: FETCH_CATALOGUE_REQUEST
  }
}

function fetchCatalogueSuccess(products) {
  return {
    type: FETCH_CATALOGUE_SUCCESS,
    products
  }
}

function fetchCatalogueFailure() {
  return {
    type: FETCH_CATALOGUE_FAILURE
  }
}

export function fetchCatalogue(id, locale) {
  return dispatch => {
    dispatch(fetchCatalogueRequest());
    return axios.get(`/sites/${id}/products`, {params: {locale: locale}})
      .then(response => {
        dispatch(fetchCatalogueSuccess(response.data.products));
      }).catch(error => {
        dispatch(fetchCatalogueFailure());
      });
  }
}
