import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { FlashReducer } from 'mui-redux-flash';
import SiteReducer from 'reducers/SiteReducer';
import CartReducer from 'reducers/CartReducer';
import OrderReducer from 'reducers/OrderReducer';
import BalanceReducer from 'reducers/BalanceReducer';
import LocaleReducer from 'reducers/LocaleReducer';
import CollectionReducer from 'reducers/CollectionReducer';
import ProductReducer from 'reducers/ProductReducer';

export default (history) => combineReducers({
  flash: FlashReducer,
  router: connectRouter(history),
  balance: BalanceReducer,
  cart: CartReducer,
  site: SiteReducer,
  order: OrderReducer,
  locale: LocaleReducer,
  collection: CollectionReducer,
  product: ProductReducer
})
