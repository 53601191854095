import React from 'react';

export const commonTheme = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  }
});

export function buttonStyle(style) {
  const buttonStyles = {semi_rounded: '14px', rounded: '30px', square: '0px'};
  return buttonStyles[style];
}
