import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';

const styles = {
  notice: {
    textAlign: 'center',
    backgroundColor: '#cce5ff',
    color: '#004085',
    padding: '10px 10px 0 10px',
    marginBottom: '1em',
    borderRadius: '5px',
    border: '1px solid #b8daff'
  },
  text: {
    color: '#004085'
  }
};

class Notice extends React.Component {
  render() {
    const { classes, text } = this.props;

    return (
      <div className={classes.notice}>
        <AnnouncementIcon />
        <Typography component="span" variant="body1" className={classes.text}>{text}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Notice);
